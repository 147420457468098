import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { Button, FormControl, Grid } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import Logo from '../assets/logo.png';
import CloseIcon from '@mui/icons-material/Close';
import api from '../api/api';
import { mask } from "../themes/masks";
import { toast } from 'react-toastify'
import CustomToast from '../components/CustomToast'

const Login = ({ setExibeIconeHome }) => {
    const [isBackendOnline, setIsBackendOnline] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showFirstLogin, setShowFirstLogin] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [passwordStrong, setPasswordStrong] = useState([]);
    const [user, setUser] = useState({
        cpf: '',
        password: ''
    });

    const handleLogin = async () => {
        setLoading(true);
        if(user.cpf === '' || user.password === '') {
            toast.error('Preencha todos os campos!');
            setLoading(false);
            return;
        }
        
        try {
            const response = await api.post('/auth', user);
            localStorage.setItem('token', response.data.token);
            window.location.href = '/home';
        } catch (error) {
            console.log(error);
            CustomToast({message: 'Usuário ou senha inválidos!', type: 'error'});
            setUser({ ...user, password: '' });
        } finally {
            setLoading(false);
        }
    }

    const handleFirstLogin = async () => {
        setLoading(true);
        if(user.cpf === '') {
            toast.error('Preencha todos os campos!');
            setLoading(false);
            return;
        }

        try {
            const mailSent = await api.post('/generateVerificationCode', { cpf: user.cpf });
            setUserEmail(mailSent.data.email);
            toast.success('Email enviado com sucesso!');
            setEmailSent(true);
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setLoading(false);
        }
    }

    const handleVerificationCode = async () => {
        setLoading(true);
        if(verificationCode === '') {
            toast.error('Preencha todos os campos!');
            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/verifyVerificationCode', { cpf: user.cpf, verificationCode: verificationCode });
            if(response.data.statusCode === 400) {
                toast.error('Código inválido');
                setLoading(false);
                return;
            }
            toast.success('Código validado com sucesso!');
            setShowNewPassword(true);
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        document.querySelector('#manifest-placeholder').setAttribute('href', '/manifest.json');
        verifyIfBackendIsOnline();
        setExibeIconeHome && setExibeIconeHome(false);
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);

    useEffect(() => {
        verifyIfIsStrongPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newPassword, confirmPassword]);

    const supressEmail = (email) => {
        const emailArray = email.split('@');
        const firstPart = emailArray[0].substring(0, 3);
        const lastPart = emailArray[0].substring(emailArray[0].length - 3, emailArray[0].length);
        return `${firstPart}...${lastPart}@${emailArray[1]}`;
    }

    const handleNewPassword = async () => {
        setLoading(true);
        if(newPassword === '' || confirmPassword === '' || !newPassword || !confirmPassword) {
            toast.error('Preencha todos os campos!');
            setLoading(false);
            return;
        }

        if(!verifyIfIsStrongPassword(newPassword)) {
            toast.error('A senha digitada não é forte o suficiente!');
            setLoading(false);
            return;
        }

        if(newPassword !== confirmPassword) {
            toast.error('As senhas não conferem!');
            setLoading(false);
            return;
        }

        try {
            const response = await api.post('/changePassword', { cpf: user.cpf, password: newPassword, verificationCode: verificationCode });
            if(response.data.statusCode === 400) {
                toast.error('Erro ao alterar senha');
                setLoading(false);
                return;
            }
            toast.success('Senha alterada com sucesso!');
            setLoading(false);
            setShowNewPassword(false);
            setShowFirstLogin(false);
            setEmailSent(false);
            setShowLogin(false);
            setVerificationCode('');
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setNewPassword('');
            setConfirmPassword('');
            setLoading(false);
        }
    }

    const verifyIfIsStrongPassword = () => {
        setPasswordStrong([]);

        if(newPassword.length < 8) setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        if((newPassword !== confirmPassword) || !newPassword || !confirmPassword) setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        if(newPassword.length > 12) setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        if(!/[A-Z]/.test(newPassword))setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        if(!/[a-z]/.test(newPassword)) setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        if(!/[0-9]/.test(newPassword))setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        if(!/[!@#$%&*()_+^]/.test(newPassword)) setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: false }]);
        else setPasswordStrong(passwordStrong => [...passwordStrong, { isValid: true }]);

        const isStrong = passwordStrong.every((item) => item.isValid === true);
        return isStrong;
    }

    const verifyIfBackendIsOnline = async () => {
        try {
            await api.get('/hc');
        } catch (error) {
            setIsBackendOnline(false);
        }
    }

    return (
        <>
            <Box sx={{
                display: "flex",
                height: "100vh",
                overflow: "hidden",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "#213648",
            }}>
                { !isBackendOnline && (
                    <Box sx={{
                        display: "flex",
                        height: "100vh",
                        overflowY: "hidden",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        bgcolor: "#213648",
                    }}>
                        <img
                            src={Logo}
                            alt="logo"
                            style={{
                                width: "70%",
                                height: "auto",
                                maxWidth: "700px",
                                minWidth: "400px",
                                marginTop: "-70px",
                            }}
                        />
                        <Typography variant="h6" sx={{ color: "#D7C46D", marginTop: "20px" }} fontWeight="bold">
                            O sistema está temporariamente indisponível.
                        </Typography>
                        <Typography variant="h6" sx={{ color: "#D7C46D" }} fontWeight="bold">
                            Tente novamente mais tarde.
                        </Typography>
                    </Box>
                )}
                
                { isBackendOnline && !showLogin && !showFirstLogin &&
                    <>
                        <img 
                            src={Logo} 
                            alt="logo" 
                            style={{ 
                                width: "60%",
                                height: "auto",
                                maxWidth: "700px",
                                minWidth: "400px",
                                marginTop: "-70px",
                            }}
                        />
                        <Button 
                            variant="contained" 
                            onClick={() => setShowLogin(true)}
                            sx={{
                                backgroundColor: "#D7C46D",
                                color: "#213648",
                                width: "15%",
                                maxWidth: "300px",
                                minWidth: "200px",
                                borderRadius: "30px",
                                "&:hover": {
                                    backgroundColor: "#D7C46D",
                                    color: "#213648"
                                }
                            }}                            
                        >
                            Login
                        </Button>

                        <Typography variant="body2" style={{ color: "#D7C46D", marginTop: "10px", cursor: "pointer" }} onClick={() => setShowFirstLogin(true)}>
                            Primeiro acesso/esqueceu senha ? Clique aqui!
                        </Typography>
                    </>
                }

                { showLogin && (
                    <Card variant="elevation" elevation={24} sx={{
                        marginTop: "-100px",
                        width: "90%",
                        padding: "10px",
                        '@media (min-width:780px)': { 
                            width: "60%",
                            padding: "10px",
                            marginTop: "-40px",
                        },
                        '@media (min-width:1024px)': {
                            width: "30%",
                            padding: "10px"
                        },
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            marginBottom: "-20px",
                        }}>
                            <CloseIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => setShowLogin(false)} />
                        </Box>

                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                <Typography variant="h5" component="h2">
                                    Área Restrita
                                </Typography>
                            </Grid>

                            <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                                <InputLabel htmlFor="cpf">CPF/CNPJ</InputLabel>
                                <OutlinedInput
                                    id="cpf"
                                    label="CPF/CNPJ"
                                    fullWidth
                                    variant="outlined"
                                    value={user.cpf}
                                    onChange={(e) => setUser({ ...user, cpf: mask(e.target.value, 'cpf') })}
                                    onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                                />
                            </FormControl>

                            <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                                <InputLabel htmlFor="password">SENHA</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    label="SENHA"
                                    type={ showPassword ? 'text' : 'password' }
                                    fullWidth
                                    variant="outlined"
                                    value={user.password}
                                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                                    onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                onMouseDown={ event => event.preventDefault() }
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }

                                />
                            </FormControl>
                                    
                            <LoadingButton
                                variant="contained"
                                color="primary"
                                fullWidth
                                margin="normal"
                                onClick={handleLogin}
                                sx={{ marginTop: "1rem", width: "150px" }}
                                loading={loading}
                            >
                                Entrar
                            </LoadingButton>
                        </CardContent>
                    </Card>
                )}

                { showFirstLogin && (
                    <Card variant="elevation" elevation={24} sx={{
                        marginTop: "-100px",
                        width: "90%",
                        padding: "10px",
                        '@media (min-width:780px)': { 
                            width: "60%",
                            padding: "10px",
                            marginTop: "-40px",
                        },
                        '@media (min-width:1024px)': {
                            width: "30%",
                            padding: "10px"
                        },
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            marginBottom: "-20px",
                        }}>
                            <CloseIcon sx={{ color: "red", cursor: "pointer" }} onClick={() => {
                                setShowFirstLogin(false);
                                setEmailSent(false);
                                setLoading(false);
                                }
                            }/>
                        </Box>

                        <CardContent sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            { !emailSent && 
                                <>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography variant="h6" align='center'>
                                            Digite seu CPF/CNPJ para cadastrar sua senha
                                        </Typography>
                                    </Grid>

                                    <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                                        <InputLabel htmlFor="email">CPF/CNPJ</InputLabel>
                                        <OutlinedInput
                                            id="email"
                                            label="CPF/CNPJ"
                                            type="email"
                                            fullWidth
                                            variant="outlined"
                                            autoComplete='off'
                                            value={user.cpf}
                                            onChange={(e) => setUser({ ...user, cpf: mask(e.target.value, 'cpf') })}
                                        />
                                    </FormControl>
                                            
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        margin="normal"
                                        onClick={handleFirstLogin}
                                        sx={{ marginTop: "1rem", width: "150px" }}
                                        loading={loading}
                                    >
                                        Cadastrar
                                    </LoadingButton>
                                </>
                            }   

                            { emailSent && !showNewPassword &&
                                <>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography variant="body2" color="textSecondary" component="p" align="center">
                                            Foi enviado um e-mail para <b>{supressEmail(userEmail)}</b> com o código de verificação!
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p" align="center">
                                            Verifique sua caixa de entrada e spam.
                                        </Typography>
                                    </Grid>
                                    <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                                        <InputLabel htmlFor="codigo">Código de verificação</InputLabel>
                                        <OutlinedInput
                                            id="codigo"
                                            label="Código de verificação"
                                            type="codigo"
                                            fullWidth
                                            variant="outlined"
                                            autoComplete='off'
                                            value={verificationCode}
                                            autoFocus={true}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                    </FormControl>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        margin="normal"
                                        onClick={handleVerificationCode}
                                        sx={{ marginTop: "1rem", width: "150px" }}
                                        loading={loading}
                                    >
                                        Confirmar
                                    </LoadingButton>
                                </>
                            }

                            { showNewPassword &&
                                <>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography variant="body2" color="textSecondary" component="p" align="center">
                                            Digite sua nova senha
                                        </Typography>
                                    </Grid>
                                    <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                                        <InputLabel htmlFor="password">Senha</InputLabel>
                                            <OutlinedInput
                                                id="password1"
                                                label="Senha"
                                                type={ showPassword ? 'text' : 'password' }
                                                fullWidth
                                                variant="outlined"
                                                autoComplete='off'
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            onMouseDown={ event => event.preventDefault() }
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                    </FormControl>
                                    <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                                        <InputLabel htmlFor="password">Confirmar senha</InputLabel>
                                            <OutlinedInput
                                                id="password"
                                                label="Confirmar senha"
                                                type={ showPassword ? 'text' : 'password' }
                                                fullWidth
                                                variant="outlined"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            onMouseDown={ event => event.preventDefault() }
                                                            edge="end"
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                    </FormControl>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        margin="normal"
                                        onClick={handleNewPassword}
                                        sx={{ marginTop: "1rem", width: "150px" }}
                                        loading={loading}
                                    >
                                        Confirmar
                                    </LoadingButton>

                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10} sx={{ marginTop: "1rem" }}>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[0].isValid ? "green" : "red" }}>
                                            * A senha deve conter no mínimo 8 caracteres
                                        </Typography>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[1].isValid ? "green" : "red" }}>
                                            * A senha não confere com a confirmação
                                        </Typography>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[2].isValid ? "green" : "red" }}>
                                            * A senha deve conter no máximo 12 caracteres
                                        </Typography>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[3].isValid ? "green" : "red" }}>
                                            * A senha deve conter pelo menos uma letra maiúscula
                                        </Typography>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[4].isValid ? "green" : "red" }}>
                                            * A senha deve conter pelo menos uma letra minúscula
                                        </Typography>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[5].isValid ? "green" : "red" }}>
                                            * A senha deve conter pelo menos um número
                                        </Typography>
                                        <Typography variant="body2" component="p" align="start" sx={{ color: passwordStrong[6].isValid ? "green" : "red" }}>
                                            * A senha deve conter pelo menos um caractere especial
                                        </Typography>
                                    </Grid>
                                </>
                            }
                        </CardContent>
                    </Card>
                )}

            </Box>

            <Box sx={{
                position: "absolute",
                bottom: "0",
                width: "100%",
                color: "#D7C46D",
                textAlign: "center",
                padding: "10px",
                backgroundColor: "#213648",
                zIndex: "1000",
            }}>
                <Typography variant="body2" component="p">
                    <b>Versão 1.0.57</b>
                </Typography>
            </Box>
        </>
    )
}

export default Login;