import { useEffect, useState } from "react"
import Navbar from "../components/NavBar"
import { Grid, Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"

export const PaginaNaoEncontrada = ({setExibeIconeHome}) => {
    const navigate = useNavigate();
    const [tempoParaVoltar, setTempoParaVoltar] = useState(5);

    useEffect(() => {
        setExibeIconeHome && setExibeIconeHome(false);
    }, [setExibeIconeHome]);

    const interval = setInterval(() => {
        setTempoParaVoltar(tempoParaVoltar - 1);
        if (tempoParaVoltar === 0) {
            navigate("/home");
        }
    }, 1000);

    useEffect(() => {
        return () => clearInterval(interval);
    }, [interval]);

    return (
        <>
        <Navbar />
        <Grid container>
            <Grid item xs={11} md={8} lg={6} sx={{ margin: "auto" }}>
                <Box marginTop="50px" marginBottom="50px" textAlign="center">
                    <Typography variant="h4" sx={{ color: "#213648" }}>
                        A página que você está procurando não foi encontrada.
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: "#213648" }}>
                        Se você acha que isso é um erro, por favor, entre em contato conosco.
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: "#213648", marginTop: "20px" }}>
                        Iremos redirecioná-lo para a página inicial em {tempoParaVoltar} segundos.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        </>
    );
};