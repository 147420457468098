import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CustomToast from "../components/CustomToast";
import Grid from '@mui/material/Unstable_Grid2';
import { Alert } from "@mui/material"
import AlertTitle from '@mui/material/AlertTitle';
import api from "../api/api";
import { mask, maskPhone, maskCell, maskCep } from "../themes/masks";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const Registration = () => {

    const [isEditing, setIsEditing] = useState(true);
    const [dataClient, setDataClient] = useState({
        nome: '',
        email: '',
        cpf: '',
        telefone: '',
        celular: '',
        cidade_label: '',
        cidade_value: '',
        uf: '',
    });
    const [optionCity, setOptionCity] = useState([]);
    const [isAlredySolicited, setIsAlredySolicited] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);

    const handleUpdateClient = async () => {
        try {
            setIsEditing(!isEditing)
            const response = await api.post('/updateSegurado', dataClient);
            if (!response.status === 200) CustomToast({ message: "Erro ao atualizar cadastro!", type: "error" });
            CustomToast({ message: "Cadastro atualizado com sucesso!", type: "success" });
            setIsAlredySolicited(true);
        } catch (error) {
            CustomToast({ message: "Ocorreu um erro ao atualizar o cadastro!", type: "error" });
            verifyIfIsAlredySolicited();
            getUserData();
            getCities();
            console.log(error);
        }
    }

    useEffect(() => {
        Promise.all([verifyIfIsAlredySolicited(), getUserData(), getCities()])
            .then(() => setIsLoadingData(false))
            .catch(() => setIsLoadingData(false))
    }, []);

    const getCities = async () => {
        try {
            const response = await api.get('/cities');
            setOptionCity(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getUserData = async () => {
        try {
            const response = await api.get("/user");
            setDataClient(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    const verifyIfIsAlredySolicited = async () => {
        try {
            const response = await api.get("/verifyIfIsAlredySolicited");
            setIsAlredySolicited(response.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    return (
        <>
            <NavBar />
            { isLoadingData ? 
                <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: "center", marginTop: "100px", alignItems: "center", height: '100px' }}>
                    <CircularProgress color="primary"/>
                    <Typography sx={{ marginLeft: "10px", marginTop: "10px" }} fontWeight="500" variant="h6" color="primary">Carregando...</Typography>
                </Box>
            :   
                <Container sx={{ backgroundColor: 'white', padding: '1rem', marginTop: '1rem' }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                    }}>
                        <Typography variant="h4">
                            Meus dados
                        </Typography>
                    </Box>
                    <Divider />

                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "1rem"
                    }}>
                        {/* alerta de dados */}
                        <Alert severity="info">
                            <AlertTitle>Atenção! </AlertTitle>
                            As informações de cadastro dos contratos já fechados <strong>não são alterados.</strong>
                        </Alert>
                    </Box>

                    { !isAlredySolicited ? 
                    <>
                        <Grid container spacing={3} mt={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Nome"
                                    id="outlined-size-small"
                                    size="small"
                                    disabled
                                    onClick={() => !isEditing && CustomToast({ message: "Não é possível editar o nome", type: "error" })}
                                    fullWidth
                                    sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                    value={dataClient.nome}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="CPF"
                                    id="outlined-size-small"
                                    size="small"
                                    disabled
                                    onClick={() => !isEditing && CustomToast({ message: "Não é possível editar o CPF", type: "error" })}
                                    fullWidth
                                    sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                    value={mask(dataClient.cpf)}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={3}>
                            <TextField
                                label="E-mail"
                                id="outlined-size-small"
                                defaultValue="ronaldom32@gmail.com"
                                size="small"
                                disabled={isEditing}
                                fullWidth
                                sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                value={dataClient.email}
                                onChange={(e) => setDataClient({ ...dataClient, email: e.target.value })}
                            />
                        </Box>
                        <Box mt={3}>
                            <Grid container spacing={3} mt={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Telefone"
                                        id="outlined-size-small"
                                        defaultValue="(11) 99999-9999"
                                        size="small"
                                        disabled={isEditing}
                                        fullWidth
                                        sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                        value={dataClient.telefone}
                                        onChange={(e) => setDataClient({ ...dataClient, telefone: maskPhone(e.target.value) })}
                                    />
                                </Grid>
                            
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Celular"
                                        id="outlined-size-small"
                                        defaultValue="(11) 99999-9999"
                                        size="small"
                                        disabled={isEditing}
                                        fullWidth
                                        sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                        value={dataClient.celular}
                                        onChange={(e) => setDataClient({ ...dataClient, celular: maskCell(e.target.value) })}
                                    />
                                </Grid>
                            </Grid>
                        </Box>     
                        <Box mt={3}>
                            <Typography variant="h4">
                                Endereço
                            </Typography>
                        </Box>
                        <Divider />

                        <Grid container spacing={3} mt={3}>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="CEP"
                                    id="outlined-size-small"
                                    defaultValue=" "
                                    size="small"
                                    disabled={isEditing}
                                    fullWidth
                                    sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                    value={dataClient.cep}
                                    onChange={(e) => setDataClient({ ...dataClient, cep: maskCep(e.target.value) })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    label="Endereço"
                                    id="outlined-size-small"
                                    defaultValue=" "
                                    size="small"
                                    disabled={isEditing}
                                    fullWidth
                                    sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                    value={dataClient.endereco}
                                    onChange={(e) => setDataClient({ ...dataClient, endereco: e.target.value.toUpperCase() })}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} mt={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Bairro"
                                    id="outlined-size-small"
                                    defaultValue=" "
                                    size="small"
                                    disabled={isEditing}
                                    fullWidth
                                    sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                    value={dataClient.bairro}
                                    onChange={(e) => setDataClient({ ...dataClient, bairro: e.target.value.toUpperCase() })}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Autocomplete
                                id="custom-autocomplete"
                                size="small"
                                variant="outlined"
                                value={dataClient.cidade_label}
                                options={optionCity}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Cidade"
                                        />
                                    );
                                }}
                                onChange={(event, newValue) => {
                                    setDataClient({...dataClient, cidade_label: newValue.label, uf: newValue.uf, cidade_value: newValue.id})
                                }}
                                disabled={isEditing}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="Estado"
                                    id="outlined-size-small"
                                    value={dataClient.uf}
                                    defaultValue=" "
                                    size="small"
                                    disabled={true}
                                    fullWidth
                                    sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                />
                            </Grid>
                        </Grid>

                        <Box mt={3}>
                            <TextField
                                label="Complemento"
                                id="outlined-size-small"
                                defaultValue=" "
                                size="small"
                                disabled={isEditing}
                                fullWidth
                                sx={{ backgroundColor: 'white', cursor: 'no-drop' }}
                                value={dataClient.complemento}
                                onChange={(e) => setDataClient({ ...dataClient, complemento: e.target.value.toUpperCase() })}
                            />
                        </Box>
                        <Divider />
                        <Box mt={3}>
                            {
                                isEditing ? (
                                    <Button variant="contained" color="primary" fullWidth onClick={() => {
                                        setIsEditing(!isEditing)
                                        CustomToast({message: 'Modo de alteração de dados', type: 'success'})
                                    }}>
                                        Editar
                                    </Button>
                                ) : (
                                    <>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={6}>
                                                <Button variant="contained" color="primary" fullWidth
                                                    onClick={() => handleUpdateClient()}
                                                >
                                                Salvar
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} sm={6}>
                                                <Button variant="contained" color="danger" fullWidth onClick={() => {
                                                    setIsEditing(!isEditing);
                                                    CustomToast({message: 'Edição dos dados cancelada', type: 'success'});
                                                    getUserData();
                                                }}>
                                                    Cancelar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            }
                        </Box>
                        </> : (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, height: '60vh', flexDirection: 'column' }}>
                            <Typography variant="h4">
                                Atenção!
                            </Typography>
                            <Typography variant="body1">
                                Sua solicitação de alteração de dados foram enviadas e encontra-se em análise. Aguarde a aprovação para realizar uma nova alteração.
                            </Typography>
                        </Box>
                    )
                }
                </Container>
            }
        </>
    );
}

export default Registration;