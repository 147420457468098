import React, { useState } from "react";
import { Typography, Box, Grid, TextField, Button } from "@mui/material"
import { House, DriveEta, Description, AccountBox, AssignmentLate, Info } from '@mui/icons-material';
import CustomToast from "../components/CustomToast";
import api from "../api/api"

const TipoApoliceItem = ({ idTipoApolice, apolice, setCarroPesquisa, carroPesquisa }) => {
    const idRamoPlaca       = [181,260,280,312,313,532,812,830];
    const idRamoResidencial = [110,130,140,141,150,160,170,180,184,270,670,700,701,710,711,712,719];
    const idRamoDescricao   = [100,211,220,310,780,811,821,910,930,960,980,999,5390,5391];
    const idRamoGarantido   = [739,746];
    const idRamoObs         = [181,330,300];
    const idRamoModelo      = [970];
    const [pesquisaPlaca, setPesquisaPlaca] = useState('');

    const realizaRequisicaoPesquisarPlaca = async () => {
        const regex = /^[A-Z]{3}[0-9]{4}$/;
        if(!regex.test(pesquisaPlaca.replace(/-/g, ''))){
            CustomToast({ message: 'Placa inválida', type: 'error' })
            setPesquisaPlaca('');
            return;
        }

        try{
            const response = await api.get(`/getCarByApolicyAndPlate?apolice=${apolice.apolice}&placa=${pesquisaPlaca.replace(/-/g, '')}`);
            setCarroPesquisa(response.data);
            CustomToast({ message: 'Placa pesquisada com sucesso', type: 'success' })
        } catch (error) {
            CustomToast({ message: 'Erro ao pesquisar placa', type: 'error' })
            console.log(error);
        }
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {   idRamoResidencial.includes(idTipoApolice) && apolice.ramo_residencial_endereco &&
                <>
                    <House sx={{ fontSize: 25, color: "#757575" }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                        <Typography sx={{ fontSize: 13, color: "black" }}>
                            Endereço
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                            {   apolice.quantidade_itens_segurados === 1 ?
                                `${apolice.ramo_residencial_endereco}`
                                :
                                `CLIQUE EM APÓLICE PARA MAIS DETALHES`
                            }
                        </Typography>
                    </Box>
                </>
            }                         
            {   idRamoPlaca.includes(idTipoApolice) && apolice.ramo_veiculo_placa &&
                <>
                    <DriveEta sx={{ fontSize: 25, color: "#757575" }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                        { apolice.quantidade_itens_segurados === 1 ?
                            <>
                                <Typography sx={{ fontSize: 13, color: "black" }}>
                                    Placa
                                </Typography>
                                <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                    {apolice.ramo_veiculo_placa.substring(0, 3)+ '-' +apolice.ramo_veiculo_placa.substring(3, 7)}
                                </Typography>
                            </>
                            :
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%" }}>
                                        <Typography sx={{ fontSize: 13, color: "black" }}>
                                            Digite Placa
                                        </Typography>
                                        <TextField
                                            id="outlined-basic"
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '100%' }}
                                            value={pesquisaPlaca}
                                            onChange={(e) => setPesquisaPlaca(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    realizaRequisicaoPesquisarPlaca();
                                                }
                                            }}
                                        />
                                    </Box>

                                    <Button
                                        variant="contained"
                                        sx={{ mt: 2.7, ml: 1, width: 150 }}
                                        onClick={() => {
                                            if(carroPesquisa){
                                                setCarroPesquisa(null);
                                                setPesquisaPlaca('');
                                            } else {
                                                realizaRequisicaoPesquisarPlaca();
                                            }
                                        }}
                                    >
                                        { carroPesquisa ? 'Limpar' : 'Pesquisar' }
                                    </Button>
                                </Box>
                            </>
                        }
                    </Box>
                </>
            }
            {   idRamoDescricao.includes(idTipoApolice) && apolice.ramo_descricao &&
                <>
                    <Description sx={{ fontSize: 25, color: "#757575" }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                        <Typography sx={{ fontSize: 13, color: "black" }}>
                            Descrição
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                            {   apolice.quantidade_itens_segurados === 1 ?
                                `${apolice.ramo_descricao}`
                                :
                                `CLIQUE EM APÓLICE PARA MAIS DETALHES`
                            }
                        </Typography>
                    </Box>
                </>
            }
            {
                idRamoGarantido.includes(idTipoApolice) && apolice.ramo_garantido &&
                <>
                    <AccountBox sx={{ fontSize: 25, color: "#757575" }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                        <Typography sx={{ fontSize: 13, color: "black" }}>
                            Garantido
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                            {   apolice.quantidade_itens_segurados === 1 ?
                                `${apolice.ramo_garantido}`
                                :
                                `CLIQUE EM APÓLICE PARA MAIS DETALHES`
                            }
                        </Typography>
                    </Box>
                </>
            }
            {
                idRamoObs.includes(idTipoApolice) && apolice.ramo_obs &&
                <>
                    <AssignmentLate sx={{ fontSize: 25, color: "#757575" }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                        <Typography sx={{ fontSize: 13, color: "black" }}>
                            Observação
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                            {   apolice.quantidade_itens_segurados === 1 ?
                                `${apolice.ramo_obs}`
                                :
                                `CLIQUE EM APÓLICE PARA MAIS DETALHES`
                            }
                        </Typography>
                    </Box>
                </>
            }
            {
                idRamoModelo.includes(idTipoApolice) && apolice.ramo_modelo &&
                <>
                    <Info sx={{ fontSize: 25, color: "#757575" }} />
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                        <Typography sx={{ fontSize: 13, color: "black" }}>
                            Modelo
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                            {   apolice.quantidade_itens_segurados === 1 ?
                                `${apolice.ramo_modelo}`
                                :
                                `CLIQUE EM APÓLICE PARA MAIS DETALHES`
                            }
                        </Typography>
                    </Box>
                </>
            }
        </Grid>
    )
}

export default TipoApoliceItem;