import { useEffect, useState } from "react"
import { Button, Typography, Box, CircularProgress } from "@mui/material"
import { PesquisaSatisfacaoStep } from "../components/PesquisaSatisfacaoStep"
import { PesquisaSatisfacaoCompleted } from "../components/PesquisaSatisfacaoCompleted"
import api from "../api/api"
import CustomToast from "../components/CustomToast"
const logo1 = require('../assets/logo1.png')
const logo2 = require('../assets/logo2.png')

const PesquisaSatisfacao = ({ setExibeIconeHome }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isHashValid, setIsHashValid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSurveyCompleted, setIsSurveyCompleted] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [surveyAnswers, setSurveyAnswers] = useState([]);

    useEffect(() => {
        setExibeIconeHome && setExibeIconeHome(false);
        validateSurveyHash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleNextStep = () => setCurrentStep(currentStep + 1)
    const handlePreviousStep = () => setCurrentStep(currentStep - 1)

    const validateSurveyHash = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const hash = urlParams.get('hash');
        if(!hash) {
            setIsHashValid(false);
            setIsLoading(false);
            return
        }
        try{
            const survey = await api.get(`/getSurveyByHash?hash=${hash}`);
            if(!survey.data?.id) {
                setIsHashValid(false);
                setIsLoading(false);
                return
            }
            if(survey.data?.finalizado) {
                setIsSurveyCompleted(true);
                setIsLoading(false);
                return
            }
            setIsHashValid(true);
            await getAllQuestions();
            setIsLoading(false);
        } catch (err) {
            setIsHashValid(false);
            setIsLoading(false);
        }
    }

    const handleSubmitSurvey = async () => {
        try{
            const isSurveyPosted = await api.post("/postSurvey", {
                hash: window.location.search.split("=")[1],
                respostas: surveyAnswers
            });
            if(!isSurveyPosted) return
            setIsSurveyCompleted(true);
            CustomToast({message: "Pesquisa enviada com sucesso! Obrigado❤️", type: "success"})
        } catch (err) {
            CustomToast({message: "Ocorreu um erro ao enviar a pesquisa. Tente novamente mais tarde.", type: "error"})
        }
    }

    const getAllQuestions = async () => {
        try{
            const questions = await api.get(`/getAllQuestions?hash=${window.location.search.split("=")[1]}`);
            if(!questions) return
            setQuestions(questions.data);
        } catch (err) {
            console.log(err);
            CustomToast({message: "Ocorreu um erro ao buscar as perguntas. Tente novamente mais tarde.", type: "error"})
            console.log(err);
        }
    }

    return (
        <Box 
            sx={{
                backgroundColor: "#213648",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column"
                sx={{
                    paddingTop: "50px",

                    '@media (max-width: 600px)': {
                        paddingTop: "20px",
                    },
                }}
            >
                <img src={logo1} alt="logo1" style={{ objectFit: "contain", width: "90%", maxWidth: "500px" }} />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#EAEDED",
                    boxShadow: "0px 4px 45px 5px rgba(0, 0, 0, 0.6)",
                    borderRadius: "8px",
                    margin: "0 250px",
                    padding: "20px 40px",
                    justifyContent: "space-between",

                    '@media (width <= 1320px)': {
                        margin: "0 150px",
                        padding: "10px 20px"
                    },

                    '@media (width <= 1080px)': {
                        margin: "0 80px",
                        padding: "10px 20px"
                    },

                    '@media (width <= 942px)': {
                        margin: "0 20px",
                        padding: "10px 20px"
                    },
                }}
            >
                <Typography variant="h4" 
                    sx={{ 
                        fontWeight: "700",
                        fontSize: "48px",
                        lineHeight: "56px",
                        textShadow: "0px 4px 3px rgba(0, 0, 0, 0.2)",

                        '@media (max-width: 600px)': {
                            fontSize: "25px !important",
                            lineHeight: "46px !important",
                        },
                    }}  
                >
                    Pesquisa de <span style={{ color: "#D7C46D" }}>Satisfação</span>
                </Typography>

                { (!isSurveyCompleted && !isLoading && isHashValid) && (
                    <>
                        { questions && questions.length > 0 && (
                            <PesquisaSatisfacaoStep
                                question={questions[currentStep]}
                                surveyAnswers={surveyAnswers}
                                setSurveyAnswers={setSurveyAnswers}
                                type={questions[currentStep].tipo_campo_resposta}
                            />
                        )}

                        <Box display="flex" justifyContent="space-between" alignItems="flex-end" flexDirection="row" marginTop="20px" position={"relative"}>
                            <Box>
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginTop: "20px",
                                        width: "200px",
                                        backgroundColor: "#213648",
                                        color: "#FFFFFF",
                                        "&:hover": {
                                            backgroundColor: "#213648",
                                            color: "#FFFFFF",
                                        },

                                        '@media (max-width: 600px)': {
                                            width: "100%",
                                        },
                                        display: currentStep === 0 ? "none" : "block",
                                    }}
                                    onClick={handlePreviousStep}
                                >
                                    Anterior
                                </Button>
                            </Box>
                            <Typography variant="subtitle2" sx={{ position: "absolute", bottom: "10px", left: "50%", transform: "translateX(-50%)" }}>
                                {currentStep + 1} de {questions.length}
                            </Typography>
                            <Box>
                                { currentStep === questions.length - 1 ? (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginTop: "20px",
                                            width: "200px",
                                            backgroundColor: "#213648",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#213648",
                                                color: "#FFFFFF",
                                            },

                                            '@media (max-width: 600px)': {
                                                width: "100%",
                                            },
                                        }}
                                        onClick={handleSubmitSurvey}
                                    >
                                        Enviar
                                    </Button>
                                )
                                :
                                (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            marginTop: "20px",
                                            width: "200px",
                                            backgroundColor: "#213648",
                                            color: "#FFFFFF",
                                            "&:hover": {
                                                backgroundColor: "#213648",
                                                color: "#FFFFFF",
                                            },

                                            '@media (max-width: 600px)': {
                                                width: "100%",
                                            },
                                        }}
                                        onClick={handleNextStep}
                                        disabled={Object.keys(surveyAnswers).length < currentStep + 1}
                                    >
                                        Próximo
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </>
                )}

                { isSurveyCompleted && <PesquisaSatisfacaoCompleted /> }

                { (!isSurveyCompleted && !isHashValid && !isLoading) && (
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop="40px">
                            <Typography
                                sx={{
                                    marginTop: "13px",
                                    fontWeight: "400",
                                    fontSize: "24px",
                                    lineHeight: "28px",
                                    marginBottom: "20px",

                                    '@media (max-width: 600px)': {
                                        marginBottom: "0px",
                                        marginTop: "0px",
                                        fontSize: "18px !important",
                                    },
                                }}
                            >
                                Desculpe, o link de acesso a pesquisa é inválido ou expirou
                            </Typography>
                        </Box>
                )}

                { isLoading && (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "50px", alignItems: "center", height: '200px' }}>
                        <CircularProgress color="primary"/>
                        <Typography sx={{ marginLeft: "10px", marginTop: "10px" }} fontWeight="500" variant="h6" color="primary">Carregando...</Typography>
                    </Box>
                )}

            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop="40px">
                <img src={logo2} alt="logo2" style={{ objectFit: "contain", width: "90%", maxWidth: "500px" }} />
            </Box>
        </Box>
    );
}

export default PesquisaSatisfacao;