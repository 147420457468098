module.exports = [
    {
        question: "O que é franquia?",
        response: "Franquia é a participação do segurado em um determinado sinistro, seja ele de automóvel, residencial, empresarial, entre outros ramos. Não são todas as coberturas da apólice que exigem a participação na franquia, mas uma vez sendo aplicada, é de obrigatoriedade do segurado se responsabilizar pela mesma. A franquia deverá sempre estar mencionada na apólice de seguros que é o contrato de prestação de serviços entre a seguradora e o segurado. Os valores são variáveis de acordo com o perfil do segurado, modelo do bem segurado e também determinado por cada seguradora. Especificamente no seguro automóvel, a franquia só é valida para perda parcial, para casos de perda total seja por roubo, furto, incêndio ou colisão, não há participação do segurado, a indenização é integral por conta da seguradora. As coberturas de vidros, faróis, retrovisores e lanternas também possuem franquia, sendo o valor proporcional de acordo com a peça. No caso de acionamento para terceiros não há cobrança de franquia, os reparos/indenização são totalmente por conta da seguradora até o limite contratado em apólice. Vale ressaltar que todos os processos de sinistros são analisados pela seguradora e podem ocorrer exceções de acordo com cada apólice ou ocorrência."
    },
    {
        question: "Como é realizado o pagamento da franquia?",
        response: "Em caso de sinistro colisão, o valor de franquia é pago diretamente para a oficina de escolha quando os reparos do veiculo forem concluídos. Detalhes sobre formas de pagamento e parcelamentos devem ser confirmados diretamente com a oficina escolhida pelo segurado. Em casos de sinistros residenciais como danos elétricos, vendaval, vidros, o valor da franquia será deduzido diretamente da indenização apurada pela seguradora."
    },
    {
        question: "O que apólice?",
        response: "Apólice é um contrato de boa fé emitido pela seguradora, assumindo o risco e mencionando todos os direitos e obrigações do segurado e da seguradora. É importante se atentar a cada informação mencionada na apólice, pois qualquer divergência pode ocasionar problemas futuros."
    },
    {
        question: "O que é sinistro?",
        response: "Sinistro é qualquer evento ocorrido com o bem ocasionando um prejuízo não premeditado, um acidente."
    },
    {
        question: "O que fazer em caso de sinistro?",
        response: "O essencial em caso de sinistro é manter contato com o seu gestor de seguros para obter orientações de como proceder, caso não seja possível e a ocorrência necessite de uma assistência imediata e inadiável, entrar em contato com a seguradora através do 0800. Em caso de colisão que não seja possível a movimentação do veiculo, pode-se acionar um guincho* para que o mesmo seja encaminhado para a residência do segurado ou para o pátio da seguradora até que próximo dia útil, com o auxilio do corretor de seguros, sejam tomadas as devidas providencias. Em caso de roubo ou furto, providenciar imediatamente a realização do boletim de ocorrência nos órgãos competentes. Para o estado de São Paulo, temos a opção de realizar o boletim de ocorrência on-line através do site: https://www.delegaciaeletronica.policiacivil.sp.gov.br/ssp-de-cidadao/pages/comunicar-ocorrencia *sempre se atentar a km de guincho contratada em apólice."
    },
    {
        question: "O que é classe de bônus?",
        response: "A classe de bônus é uma pontuação fornecida pelas seguradoras quando um seguro é renovado sem utilização por sinistro da apólice anterior, o bônus é vinculado ao segurado não tendo qualquer relação com o modelo do veiculo, com a corretora ou até mesmo com a seguradora. Essa pontuação, a cada acumulo, tem o intuito de fornecer um “desconto” na renovação, sendo a classe máxima de bônus a ser atingido é a 10. Os bônus só serão deduzidos em acionamentos decorrentes de sinistro como: danos elétricos, vendaval, colisão, incêndio, roubo, furto ou acionamentos a terceiros, as utilizações de trocas de vidros, faróis, retrovisores, lanternas e assistências 24 horas como guincho, carga na bateria, chaveiro, elétrica e hidráulica não desclassificam bônus."
    },
    {
        question: "Qual a diferença entre a cobertura de raio e danos elétricos?",
        response: "A cobertura de raio é aplicada em caso de reparação da estrutura do imóvel, quando cai um raio dentro do terreno de um imóvel segurado, normalmente ele causa danos como rachaduras e desmoronamentos (danos estes a serem indenizados pela seguradora). Já o dano elétrico e um dano causado por uma descarga elétrica que vem a queimar equipamentos eletrônicos."
    }
]

