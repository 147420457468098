import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const Modal = ({ children, title, onConfirm, confirmText, isOpen, setIsOpen, CancelText, maxWidth }) => {
    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth maxWidth={maxWidth}
            sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(2px)',
            }}
        >
            { title && <DialogTitle>{title}</DialogTitle> }
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                { confirmText &&
                    <Button onClick={onConfirm} color="primary" variant="contained"> 
                        {confirmText}
                    </Button>
                }

                {CancelText && 
                    <Button onClick={() => setIsOpen(false)} color="danger" variant="contained">
                        {CancelText}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
}
export default Modal;