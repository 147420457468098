import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Toolbar,
  Typography,
  makeStyles,
  Box
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoPreto from "../assets/logo_preto.png";
import LogoutIcon from '@mui/icons-material/Logout';
import Modal from "../components/Modal";
import ArticleIcon from '@mui/icons-material/Article';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import HomeIcon from '@mui/icons-material/Home';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const useStyles = makeStyles((theme) => ({
  link: { textDecoration: "none", color: "black", fontSize: "25px", display: "flex", alignItems: "center" },
  icon: { color: "black", marginRight: theme.spacing(1), fontSize: "2.5rem" },
  logo: { flexGrow: "1", cursor: "pointer" },
  desconectButton: {
    "&:hover": {
      transform: "scale(1.2)",
      transition: "transform 0.2s ease-in-out",
    },
  }
}));

function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [isManager, setIsManager] = useState(false);
  const [isProdutor, setIsProdutor] = useState(false);

  useEffect(() => {
    try{
        const token = localStorage.getItem("token");
        if (token) {
          const user = JSON.parse(atob(token.split(".")[1]));
          setIsManager(user.isManager);
          setIsProdutor(user.isProdutor);
        }
    } catch (error) {
        console.log(error);
    }
  }, []);

  return (
    <>
      <Drawer
        anchor="top"
        sx={{ width: 250, color: "#fff" }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onClick={() => setOpenDrawer(false)}
      >
        <Toolbar>
          <Typography variant="h4" className={classes.logo} onClick={() => navigate("/home")}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "10px"}}>
              <img src={LogoPreto} alt="logo" style={{ width: "230px", height: "100%" }} />
            </Box>
          </Typography>
          <CloseIcon onClick={() => setOpenDrawer(false)} />
        </Toolbar>

          <List height="100vh">
            <Divider />
            <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                    <Link to="/home" className={classes.link}>
                    <HomeIcon className={classes.icon} /> Home
                    </Link>
                </ListItemText>
            </ListItem>
            { isManager && (
              <>
              <Divider />
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link to="/apr_alt_cadastro" className={classes.link}>
                    <FactCheckIcon className={classes.icon} /> Apr. Alt. Cadastro
                  </Link>
                </ListItemText>
              </ListItem>
              </>
            )}
            <Divider />
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/apolice" className={classes.link}>
                  <ArticleIcon className={classes.icon} /> Apólices
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Link to="/sinistro" className={classes.link}>
                  <ErrorIcon className={classes.icon} /> Sinistros
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Link to="/registration" className={classes.link}>
                  <AccountCircleIcon className={classes.icon} /> Cadastro
                </Link>
              </ListItemText>
            </ListItem>
            { isProdutor && (
              <>
                <Divider />
                <ListItem>
                  <ListItemText>
                    <Link to="/buscar_segurados" className={classes.link}>
                      <PersonSearchIcon className={classes.icon} /> Buscar Segurados
                    </Link>
                  </ListItemText>
                </ListItem>
              </>
            )}
            <Divider />
            <ListItem>
              <ListItemText>
              <Button variant="contained" color="danger" className={classes.desconectButton} onClick={() => setOpenModal(true)}>
              <LogoutIcon /> Desconectar 
            </Button>
              </ListItemText>
            </ListItem>
          </List>
      </Drawer>
      <IconButton
        className={classes.icon}
        style={{ color: "#fff" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
      <Modal 
        isOpen={openModal} 
        setIsOpen={setOpenModal} 
        confirmText="Desconectar"
        CancelText="Cancelar"
        title="Deseja realizar logout?"
        onConfirm={() => {
            localStorage.removeItem("token");
            navigate("/");
        }}
        maxWidth={"sm"}
      />
    </>
  );
}

export default DrawerComponent;
