import React from "react";
import { Typography, Box } from "@mui/material"
import { notFoundImg } from "../assets/notFoundImg";

const InsuranceCompany = ({ insuranceCompany }) => {

    return (
        <Box>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginTop: "3px" }}>
                <Typography sx={{ color: "#213648", fontWeight: 500, pl: 2, fontSize: 14 }}>
                    {insuranceCompany.nome}
                </Typography>
                { insuranceCompany.logo &&

                    <img 
                        src={`${insuranceCompany.logo ? 'data:image/png;base64,' + insuranceCompany.logo : notFoundImg}`}
                        alt="logo" 
                        style={{ height: "50px", width: "100px", objectFit: "contain", alignSelf: "flex-end", marginLeft: "auto", marginRight: "20px" }}
                    /> 
                }
            </Box>
        </Box>
    );
}
export default InsuranceCompany;
