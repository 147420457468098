import RoutesApp from "./routes/RoutesApp.js";
import 'react-toastify/dist/ReactToastify.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  return (
    <>
        <RoutesApp />
    </>
  );
}

export default App;
