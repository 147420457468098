import Typography  from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { Link } from "@mui/material";
import Fade from '@mui/material/Fade';
import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const SinitroItem = ({ sinistro }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Grid container spacing={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: 2 }}>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8} sx={{ boxShadow: '0px 6px 16px -1px rgba(0,0,0,0.2)', borderRadius: '5px', cursor: 'pointer' }}>
                    
                    <Grid container>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: '#D7C46D', borderTopLeftRadius: '5px', padding: '5px' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#213648' }}> 
                                    APÓLICE {sinistro.apolice}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', backgroundColor: '#213648', borderTopRightRadius: '5px', padding: '5px' }}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#FFF', textTransform: 'uppercase' }}>
                                    {sinistro.status_sinistro || 'Aberto'}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
            
                    <Grid container spacing={0} sx={{ backgroundColor: '#FFF' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ padding: '10px 20px' }}>
                            <Box sx={{ backgroundColor: '#FFF' }}>
                                <Typography variant="body" sx={{ color: '#213648' }}>
                                    <b>RAMO:</b> {sinistro.ramo}
                                </Typography>
                            </Box>
                            <Box sx={{ backgroundColor: '#FFF' }}>
                                <Typography variant="body" sx={{ color: '#213648' }}>
                                    <b>COBERTURA:</b> {sinistro.cobertura}
                                </Typography>
                            </Box>
                            <Box sx={{ backgroundColor: '#FFF' }}>
                                <Typography variant="body" sx={{ color: '#213648' }}>
                                    <b>DATA ABERTURA:</b> {moment(sinistro.data_inc).format('DD/MM/YYYY')}
                                </Typography>
                            </Box>
                            { sinistro.localsinistro && (
                                <Box sx={{ backgroundColor: '#FFF' }}>
                                    <Typography variant="body" sx={{ color: '#213648' }}>
                                        <b>LOCAL SINISTRO:</b> <Link href={`https://www.google.com/maps/search/?api=1&query=${sinistro.localsinistro}-${sinistro.cepsinistro}`} target="_blank">{sinistro.localsinistro} - {sinistro.cepsinistro}</Link>
                                    </Typography>
                                </Box>
                            )}
                            { sinistro.avarias_bens && (
                                <Box sx={{ backgroundColor: '#FFF' }}>
                                    <Typography variant="body" sx={{ color: '#213648' }}>
                                        <b>BENS AVARIADOS:</b> {sinistro.avarias_bens}
                                    </Typography>
                                </Box>
                            )}
                            <Box sx={{ backgroundColor: '#FFF' }}>
                                <Typography variant="body" sx={{ color: '#213648' }}>
                                    <b>SEGURADORA:</b> {sinistro.seguradora}
                                </Typography>
                            </Box>
                            <Box sx={{ borderBottom: '1px solid #D7C46D', width: '100%', height: '1px' }}></Box>
                            { sinistro.ramo === 'RCFV' && (
                                <Box 
                                    sx={{ 
                                        borderBottom: '1px solid #D7C46D', 
                                        width: '100%',
                                        backgroundColor: '#EAEDED', 
                                        flexDirection: 'column', 
                                        display: 'flex',
                                        padding: '5px 20px',
                                    }}
                                >
                                    <Typography variant="body" sx={{ color: '#213648', fontWeight: 'bold', textAlign: 'center' }}>
                                        DADOS DO RECLAMANTE
                                    </Typography>
                                    <Typography variant="body" sx={{ color: '#213648' }}>
                                        <b>NOME:</b> {sinistro.reclamante}
                                    </Typography>
                                    { sinistro.placareclamante && (
                                        <Typography variant="body" sx={{ color: '#213648' }}>
                                            <b>PLACA:</b> {sinistro.placareclamante}
                                        </Typography>
                                    )}
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                                <Typography variant="body" sx={{ color: '#213648', fontWeight: 'bold', textAlign: 'center' }}
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? 'FECHAR' : 'MAIS DETALHES'}
                                </Typography>
                                <ExpandLessIcon sx={{ ml: 1 }} fontSize="large" style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.3s ease-in-out' }} />
                            </Box>
                            {
                                open &&
                                <Fade in={open}>
                                    <Box 
                                        sx={{ 
                                            backgroundColor: '#fff', 
                                            padding: '0 20px', 
                                            display: 'flex', 
                                            alignItems: 'center', 
                                            justifyContent: 'center', 
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Box>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                                                SINISTRO:
                                            </Typography>
                                            <Typography component="body2" variant="body2" sx={{ color: '#213648' }}>
                                                {sinistro.id_sinistro}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "bold", marginRight: "5px", color: '#213648' }}>
                                                DATA OCORRÊNCIA:
                                            </Typography>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "400", color: '#213648' }}>
                                                { sinistro.datasinistro ? moment(sinistro.datasinistro).format("DD/MM/YYYY") : "Não informado" }
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "bold", marginRight: "5px", color: '#213648' }}>
                                                DATA COMUNICAÇÃO: 
                                            </Typography>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "400", color: '#213648' }}>
                                                { sinistro.datacomunicacao ? moment(sinistro.datacomunicacao).format("DD/MM/YYYY") : "Não informado" }
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "bold", marginRight: "5px", color: '#213648' }}>
                                                DATA DE PAGAMENTO: 
                                            </Typography>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "400", color: '#213648' }}>
                                                { sinistro.datapagamento ? moment(sinistro.datapagamento).format("DD/MM/YYYY") : "Não informado" }
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "bold", marginRight: "5px", color: '#213648' }}>
                                                VALOR INDENIZÁVEL:
                                            </Typography>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "400", color: '#213648' }}>
                                                { sinistro.valorindenizacao ? "R$ " + sinistro.valorindenizacao.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "Não informado"}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "bold", marginRight: "5px", color: '#213648' }}>
                                                TIPO DE PAGAMENTO: 
                                            </Typography>
                                            <Typography component="body2" variant="body2" sx={{ fontWeight: "400", color: '#213648' }}>
                                                { sinistro.tipopagamento === 'A' && 'INDENIZADO' }
                                                { sinistro.tipopagamento === 'B' && 'INDENIZAÇÃO TOTAL POR ACIDENTE' }
                                                { sinistro.tipopagamento === 'C' && 'INDENIZAÇÃO TOAL POR ROUBO / FURTO ACESSÓRIOS' }
                                                { sinistro.tipopagamento === 'D' && 'ENCERRADO SEM INDENIZAÇÃO' }
                                                { sinistro.tipopagamento === 'E' && 'INDENIZAÇÃO PARCIAL POR ROUBO / FURTO' }
                                                { !sinistro.tipopagamento && 'NÃO INFORMADO' }
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Fade>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>


        </>
    )
}

export default SinitroItem;