import { Box, Typography } from "@mui/material"

const SquareInput = ({value, selected, ...props}) => {
    return (
        <Box
            {...props}
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor:
                    selected ? value <= 3 ? 
                        "rgba(125, 45, 45, 0.5)" :
                        value <= 6 ? "rgba(250, 255, 0, 0.5)" :
                        "rgba(52, 125, 45, 0.5)" :
                    value <= 3 ? 
                        "rgba(125, 45, 45, 0.16)" :
                    value <= 6 ? "rgba(250, 255, 0, 0.16)" :
                        "rgba(52, 125, 45, 0.16)",
                borderRight: value === 10 ? "none" : "0.5px solid #ccc",
                borderTopRightRadius: value === 10 ? "5px" : "0px",
                borderBottomRightRadius: value === 10 ? "5px" : "0px",
                borderTopLeftRadius: value === 1 ? "5px" : "0px",
                borderBottomLeftRadius: value === 1 ? "5px" : "0px",

                '&:hover': {
                    backgroundColor: value <= 3 ? "rgba(125, 45, 45, 0.5)" : value <= 6 ? "rgba(250, 255, 0, 0.5)" : "rgba(52, 125, 45, 0.5)"
                },

                '@media (max-width: 930px)': {
                    '.MuiTypography-h4' : {
                        fontSize: "18px"
                    }
                },
            }}
        >
            <Typography variant="h4" component="div">
                {value}
            </Typography>
        </Box>
    )
}

const AvaliationBar = ({question, setSurveyAnswers, surveyAnswers}) => {
    const handleSelectAvaliation = (value, question) => {
        setSurveyAnswers(prevState => {
            return {
                ...prevState,
                [question.id]: value
            }
        })
    }

    return (
        <Box display="flex" justifyContent="center" alignItems="center" 
            sx={{ 
                width: "740px",
                height: "74px",
                border: "1px solid #1F3243",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "0px 4px 3px rgba(0,0,0,0.25)",

                '@media (width <= 930px)': {
                    width: "70%",
                    height: "40px",
                    margin: "0 20px"
                },

                '@media (width <= 600px)': {
                    width: "330px",
                    height: "40px",
                    margin: "0 20px"
                },
            }}>
            {
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value, index) => {
                    return (
                        <SquareInput
                            value={value} 
                            key={index}
                            onClick={() => handleSelectAvaliation(value, question)}
                            selected={surveyAnswers[question.id] === value}
                        />
                    )
                })
            }
        </Box>
    )
}

export default AvaliationBar