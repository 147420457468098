import React, { useEffect, useMemo } from "react";
import { useState } from "react";
import NavBar from "../components/NavBar"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Footer from "../components/Footer"
import Divider from '@mui/material/Divider';
import { Grid } from "@mui/material";
import Modal from "../components/Modal";
import { useNavigate } from "react-router-dom";
import jwt from 'jwt-decode'
import api from "../api/api";
import { notFoundImg } from "../assets/notFoundImg";
import CircularProgress from '@mui/material/CircularProgress';
import Carousel from 'react-material-ui-carousel';

const Home = ({ setExibeIconeHome }) => {
    const [productsList, setProductsList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [user, setUser] = useState({nome: 'Segurado'});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [screenWidth] = useState(window.innerWidth);

    useEffect(() => {
        setExibeIconeHome && setExibeIconeHome(false);
        try{
            setUser(jwt(localStorage.getItem('token')))
        }
        catch (error) {
            navigate('/login')
        }
        registerUserLogin();
        getAllProducts();

        return () => {
            setExibeIconeHome(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAllProducts = async () => {
        try{
            const response = await api.get('/products');
            const products = response.data;
            products.forEach(product => {
                product.base64Img = `data:image/jpeg;base64,` + convertArrayBufferToBase64(product.foto?.data);
            });
            setProductsList(products);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const registerUserLogin = async () => {
        try{
            await api.post('/registerUserLogin', { idsegurado: user.id });
        } catch (error) {
            console.log(error);
        }
    }

    const convertArrayBufferToBase64 = (buffer) => {
        if(!buffer) return notFoundImg;
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    };

    const Item = ({item}) => {
        return (
            <div style={{ position: 'relative', width: '100%', height: '100%', cursor: 'pointer' }}
                onClick={() => {
                    setShowModal(true);
                    setSelectedProduct(item);
                }}
            >
                <img 
                    src={item.base64Img}
                    alt={item.descricao}
                    style={{ objectFit: 'cover', width: '100%', height: '50vh', borderRadius: '5px'}}
                />
                <Box sx={{ 
                    position: 'absolute', 
                    padding: '10px',
                    bottom: '50px',
                    left: '10px', 
                    right: '10px', 
                    backgroundColor: 'rgba(0,0,0,0.6)', 
                    color: '#fff', 
                    borderRadius: '10px'
                }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography variant="h5" component="h2">
                            {item.descricao}
                        </Typography>
                    </div>
                </Box>
            </div>
        )
    }

    const memoListProducts = useMemo(() => {
        return (
            productsList?.map((product, index) => (
                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={index} 
                sx={{
                        cursor: "pointer", 
                        "&:hover": { transform: "scale(1.05)", transition: "all 0.3s ease-in-out" },
                    }}
                    onClick={() => {
                        setShowModal(true);
                        setSelectedProduct(product);
                    }}
                >
                    <img src={product.base64Img} alt={product.name}
                        style={{ 
                            height: "auto",
                            maxHeight: "150px",
                            minHeight: "150px",
                            borderRadius: "10px",
                            resizeMode: "cover", 
                            width: "100%",
                            objectFit: "cover",
                            marginBottom: "-5px",
                        }}
                    />
                    <Typography sx={{ ml: "10px", fontWeight: "bold", fontSize: "12px" }}>
                        {product.descricao}
                    </Typography>
                </Grid>
            ))
        )
    }, [productsList])

    const memoCarousel = useMemo(() => {
        return ( productsList.map( (item, i) => <Item key={i} item={item} /> ) )
    }, [productsList])
            
    return (
        <>
            <NavBar />
            <Container sx={{ mb: 10 }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "15px",
                }}>
                    <Typography variant="h6">
                        Olá <span style={{ color: "#D7C46D", fontWeight: "bold" }}>{user.nome?.split(' ')[0]}</span>, seja bem vindo(a)!
                    </Typography>
                    <Typography variant="subtitle1" sx={{ lineHeight: "1.5" }}>
                        Estamos felizes em ter você aqui conosco. <br />
                        Aqui você encontra todas as informações de seus negócios e muito mais. <br />
                        O Aplicativo da Horizonte foi feito para estarmos sempre presente e pensando em você!
                    </Typography>
                </Box>
                <Divider />

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6" sx={{ marginTop: "10px" }}>
                            Conheça todos nossos produtos
                        </Typography>
                    </Grid>
                </Grid>

                {loading && (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "100px", alignItems: "center", height: '100px' }}>       
                        <CircularProgress color="primary"/>
                        <Typography sx={{ marginLeft: "10px", marginTop: "10px" }} fontWeight="500" variant="h6" color="primary">Carregando...</Typography>
                    </Box>
                )}

                <Carousel
                    animation="slide"
                    cycleNavigation={true}
                    duration={800}
                    height="55vh"
                    interval={5000}
                    navButtonsAlwaysVisible={false}
                    indicators={screenWidth > 391}
                    indicatorContainerProps={{
                        style: {
                            position: 'absolute',
                            bottom: '10px',
                            textAlign: 'center',
                            width: '100%',
                            zIndex: 1000
                        }
                    }}
                >
                    {
                        memoCarousel
                    }
                </Carousel>

                <Grid container spacing={1}>
                    {memoListProducts}
                </Grid>

            </Container>
            <Footer position={'fixed'} />
            <Modal 
                isOpen={showModal}
                setIsOpen={() => {
                    setShowModal(showModal => !showModal);
                }}
                confirmText={"FALE CONOSCO"}
                CancelText={"Fechar"}
                maxWidth={"lg"}
                onConfirm={() => navigate("/atendimento")}
            >
                <img src={selectedProduct?.base64Img} alt={selectedProduct.name}
                    style={{
                        height: "auto",
                        maxHeight: "300px",
                        minHeight: "150px",
                        resizeMode: "cover",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: "5px",
                    }}
                />
                <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
                    {selectedProduct.descricao}
                </Typography>
                <Divider />
                <Typography variant="body2" sx={{ marginTop: "10px" }}>
                    {selectedProduct.obs}
                </Typography>
            </Modal>
        </>
    );
}

export default Home;
