/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, Button } from "@mui/material";
import NavBar from "../components/NavBar"
import { useLocation } from "react-router-dom";
import TableCustom from "../components/Table"
import { useEffect, useState } from "react";
import moment from "moment"
import api from "../api/api"
import Modal from "../components/Modal";
import Coberturas from "../components/Coberturas";

const ApoliceSelecionada = () => {
    const { state } = useLocation()
    const [apolice] = useState(state.apolice)
    const [columns, setColumns] = useState([])
    const [rows, setRows] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [coberturas, setCoberturas] = useState([])
    const [modalTitle, setModalTitle] = useState("")

    useEffect(() => {
        getApoliceItens()
    }, [])

    const handleOpenModal = (param) => {
        setModalTitle(param.veiculo_descricao)
        setCoberturas(param.coberturas)
        setIsOpen(true)
    }

    const getApoliceItens = async () => {
        const apoliceItens = await api.get(`/apoliceItens?apolice=${apolice.apolice}`)
        if(apolice.ramo === "AUTO") {
            setColumns([
                { field: 'veiculo_descricao', headerName: 'VEÍCULO', width: 400 },
                { field: 'veiculo_cor', headerName: 'COR', width: 120, align: "center", headerAlign: "center" },
                { field: 'veiculo_modelo', headerName: 'MODELO', width: 120, align: "center", headerAlign: "center" },
                { field: 'veiculo_placa', headerName: 'PLACA', width: 120, align: "center", headerAlign: "center" },
                { field: 'veiculo_renavam', headerName: 'RENAVAM', width: 120, align: "center", headerAlign: "center" },
                { field: 'veiculo_chassis', headerName: 'CHASSI', width: 200, align: "center", headerAlign: "center" },
                { field: 'veiculo_coberturas', headerName: 'COBERTURA', width: 250, renderCell: (params) => { return <Button variant="contained" color="primary" size="small" onClick={()=>handleOpenModal(params.row)}>VER COBERTURAS</Button> } },
            ])
        }

        if(apolice.ramo === "RESIDENCIAL") {
            setColumns([
                { field: 'residencial_enderecorisco', headerName: 'ENDEREÇO', width: 400 },
                { field: 'residencial_bairrorisco', headerName: 'BAIRRO', width: 200, align: "center", headerAlign: "center" },
                { field: 'residencial_ceprisco', headerName: 'CEP', width: 120, align: "center", headerAlign: "center" },
                { field: 'residencial_cidaderisco', headerName: 'CIDADE', width: 200, align: "center", headerAlign: "center" },
                { field: 'residencial_coberturas', headerName: 'COBERTURA', width: 150, renderCell: (params) => { return <Button variant="contained" color="primary" size="small" onClick={()=>handleOpenModal(params.row)}>VER COBERTURAS</Button> } },
            ])
        }

        setRows(apoliceItens.data);
    }

    return (
        <>
            <NavBar />
            <Grid 
                container
                sx={{ mt: 2, pb: 4, bgcolor: "#fff", width: "80%", height: "100%", marginLeft: "auto", marginRight: "auto", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.07)", borderRadius: "5px", 
                '@media (max-width: 600px)': {
                    width: "100%",
                    height: "100%"
                }
            }}
            >
                <Grid item xs={12} sx={{ backgroundColor: "#213648", borderTopRightRadius: "5px", borderTopLeftRadius: "5px" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#D7C46D', padding: "10px" }}>
                            Dados da Apólice
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={6} mb={1} mt={2} >
                    <Box sx={{ p:0, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Typography variant="h6">Nº Apólice</Typography>
                        <Typography color="#E4CF6F" variant="body">{apolice.apolice}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Typography variant="h6">Seguradora</Typography>
                        <Typography color="#E4CF6F" variant="body">{apolice.seguradora}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Typography variant="h6">Início</Typography>
                        <Typography color="#E4CF6F" variant="body">{moment(apolice.iniciovigencia).format("DD/MM/YYYY")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} mb={1}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Typography variant="h6">Fim</Typography>
                        <Typography color="#E4CF6F" variant="body">{moment(apolice.fimvigencia).format("DD/MM/YYYY")}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Typography variant="h6">Parcelas</Typography>
                        <Typography color="#E4CF6F" variant="body">{apolice.total_parcelas}x</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
                        <Typography variant="h6">Valor Total</Typography>
                        <Typography color="#E4CF6F" variant="body">R$ {parseFloat(apolice.premioconfirmado).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Typography>
                    </Box>
                </Grid>
            </Grid>
            
            <Grid
                container
                sx={{ mt: 2, pb: 4, bgcolor: "#fff", width: "80%", height: "100%", marginLeft: "auto", marginRight: "auto", boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.07)", borderRadius: "5px",
                '@media (max-width: 600px)': {
                    width: "100%",
                    height: "100%"
                }
            }}>
                <Grid item xs={12} sx={{ backgroundColor: "#213648", borderTopRightRadius: "5px", borderTopLeftRadius: "5px" }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#D7C46D', padding: "10px" }}>
                            Bens Segurados
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} p={3}>
                    <TableCustom columns={columns} rows={rows} showToolbar={true} />
                </Grid>
            </Grid>
            <Modal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                confirmText="Fechar"
                title={modalTitle}
                onConfirm={() => setIsOpen(false)}
                children={<Coberturas coberturas={coberturas} />}
                maxWidth={"xl"}
            />
        </>
    )
}

export default ApoliceSelecionada