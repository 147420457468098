import React from 'react';
import { Box, Typography, Input } from '@mui/material';

export const PesquisaSatisfacaoTextField = ({ question, setSurveyAnswers, surveyAnswers }) => {
    return (
        <>
            <Typography variant="subtitle2" marginTop={2} >Gostaríamos que nos fornecesse mais detalhes sobre a sua experiência.</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Input
                    sx={{
                        width: "100%",
                        marginBottom: "20px",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #213648",
                        borderRadius: "4px",
                        padding: "10px",
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "#213648",

                        '@media (max-width: 600px)': {
                            width: "100%",
                        },
                    }}
                    placeholder="Digite aqui"
                    multiline
                    rows={4}
                    onChange={(e) => setSurveyAnswers(prevState => ({ ...prevState, [question.id]: e.target.value }))}
                    value={surveyAnswers[question.id]}
                />
            </Box>
        </>
    );
}