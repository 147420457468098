import { Box } from "@mui/system";
import { Typography } from "@mui/material";	
import { useState } from "react";

const Footer = () => {
    const [year] = useState(new Date().getFullYear());

    return (
        <Box id="footer" sx={{ 
            backgroundColor: "#213648",
            color: "white",
            bottom: "0",
            width: "100%",
            height: "2.5rem",
            //alway show the footer at the bottom of the page
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: "1.7rem",
            marginTop: "auto",
            borderTop: "1px solid #fff",
            zIndex: "1",
            fontSize: "0.8rem",
            fontWeight: "bold",
        }}>
            <Typography variant="body2" component="p" sx={{ color: "white", textAlign: "center" }}>
                Horizonte Corretora de Seguros ®{year} <br /> Todos os direitos reservados.
            </Typography>
        </Box>
    )
}

export default Footer;