import React, { useEffect } from "react";
import NavBar from "../components/NavBar"
import { Card, Typography, CardContent, Grid, Avatar, Badge, Box } from "@mui/material"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import faq from "../assets/faq";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import api from "../api/api";

const FrequentlyAnswers = () => {
    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            width: 15,
            height: 15,
            borderRadius: 15,
            '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 4s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
        },
        '@keyframes ripple': {
            '0%': {
            transform: 'scale(.8)',
            opacity: 1,
            },
            '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
            },
        },
    }));

    const [arrayFaq] = React.useState(faq);
    const [productorData, setProductorData] = React.useState([]);

    useEffect(() => {
        getProductorData();
    }, [])

    const getProductorData = async () => {
        try {
            const response = await api.get(`/productorData`);
            setProductorData(response.data[0]);
        } catch (error) {
            console.log(error);
        }
    }

    const convertArrayBufferToBase64 = (buffer) => {
        let binary = '';
        let bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    };
    
    return (
        <>
            <NavBar />
            <Grid container spacing={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <Grid item xs={12} sm={10} md={10} lg={8} xl={8}>
                    <Card sx={{ marginLeft: "10px", marginTop: "20px", marginRight: "10px" }}>
                        <CardContent>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={4} lg={2} xl={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", "@media (width > 768px)": { justifyContent: "flex-end", display: "flex", mr: 5 }}}>
                                        <StyledBadge
                                            overlap="circular"
                                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                            variant="dot"
                                        >
                                            <Avatar alt={productorData.nome} src={productorData.foto ? `data:image/jpeg;base64,${convertArrayBufferToBase64(productorData.foto.data)}` : ""} sx={{ width: 100, height: 100 }} />
                                        </StyledBadge>
                                    </Box>
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={10} xl={10} >
                                    <Typography variant="h5" component="div" sx={{ marginTop: "10px" }}>
                                        {productorData.nome}
                                    </Typography>
                                    <Typography variant="body2">
                                        {productorData.saudacao1} &#128075; {productorData.saudacao2}
                                    </Typography>

                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary">
                                            Entre em contato comigo através dos canais abaixo:
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ ml: 2, mt: 2 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <WhatsAppIcon sx={{ width: 25, height: 25, color: "#25D366", mr: 1 }} />
                                        <Typography variant="caption" color="text.secondary">
                                            <a href={`https://api.whatsapp.com/send?phone=55${productorData.whatsapp}&text=Ol%C3%A1%2C%20gostaria%20de%20mais%20informações`} target="_blank" rel="noreferrer"> {productorData.whatsapp} </a>
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <LocalPhoneOutlinedIcon sx={{ width: 25, height: 25, color: "#000000", mr: 1 }} />
                                        <Typography variant="caption" color="text.secondary">
                                            <a href={`tel:${productorData.telefone}`}>{productorData.telefone}</a>
                                        </Typography>
                                    </Box> 
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{ ml: 2, mt: 2, "@media (width < 900px)": { mt: -0.4 } }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <MailOutlineIcon sx={{ width: 25, height: 25, color: "#D44638", mr: 1 }} />
                                        <Typography variant="caption" color="text.secondary">
                                            <a href={`mailto:${productorData.email}`}>{productorData.email?.toLowerCase()}</a>
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <LocationOnOutlinedIcon sx={{ width: 25, height: 25, color: "#000000", mr: 1 }} />
                                        <Typography variant="caption" color="text.secondary">
                                            <a href="https://www.google.com/maps/search/Avenida+Bras%C3%ADlia,+585+-+Vila+%C3%81urea+-+Mogi+Mirim%2FSP/@-22.442467,-46.964183,15z?hl=en-US" target="_blank" rel="noreferrer">Avenida Brasília, 585 - Vila Áurea - Mogi Mirim/SP</a>
                                        </Typography>
                                    </Box>
                                        

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <Typography variant="h6" component="div" sx={{ marginTop: "20px", marginLeft: "10px", mb: 1 }}>
                        Dúvidas Frequentes
                    </Typography>

                    {arrayFaq.map((item, index) => (
                        <Accordion key={index} sx={{ marginLeft: "10px", marginRight: "10px" }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>{item.question}</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography variant="body2" color="text.secondary">
                                    {item.response}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                   
                </Grid>
            </Grid>

        </>
    );
};

export default FrequentlyAnswers;
