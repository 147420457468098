import { toast } from "react-toastify"

const CustomToast = ({ message, type }) => {
    if(type === "success") {
        toast.success(message);
    }else if(type === "error") {
        toast.error(message);
    }else if(type === "info") {
        toast.info(message);
    }
    
}

export default CustomToast;