import React from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';

export const PesquisaSatisfacaoSelectOption = ({ question, setSurveyAnswers, surveyAnswers }) => {
    return (
        <>
            <Typography variant="subtitle2" marginTop={2} >Clique na opção que melhor descreve sua experiência.</Typography>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Select
                    fullWidth
                    onChange={(e) => setSurveyAnswers(prevState => ({ ...prevState, [question.id]: e.target.value }))}
                    value={surveyAnswers[question.id] || "Selecione"}
                >
                    <MenuItem value="Selecione" disabled>Selecione</MenuItem>
                    <MenuItem value="Sim">Sim</MenuItem>
                    <MenuItem value="Não">Não</MenuItem>
                </Select>
            </Box>
        </>
    );
}