import TableCustom from './Table'

const Coberturas = ({ coberturas }) => {
    const columns = [
        { field: 'cobertura', headerName: 'COBERTURA', width: 400 },
        { field: 'importanciasegurada', headerName: 'IMPORTÂNCIA SEGURADA', width: 400 },
        { field: 'franquia', headerName: 'FRANQUIA', width: 400 },
    ]

    return (
        <TableCustom columns={columns} rows={coberturas} />
    )
}

export default Coberturas;