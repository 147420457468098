const axios = require('axios');

const getLocalStorageToken = () => {
    if ( localStorage.getItem('token') ) {
        return localStorage.getItem('token');
    }
    return '';
}

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Authorization': `${getLocalStorageToken()}`
    }
});

module.exports = api;