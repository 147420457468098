import React from 'react';
import { Box, Typography } from '@mui/material';
import AvaliationBar from './AvaliationBar';
import AvaliationEmoji from './AvaliationEmoji';
import { PesquisaSatisfacaoTextField } from './PesquisaSatisfacaoTextField';
import { PesquisaSatisfacaoSelectOption } from './PesquisaSatisfacaoSelectOption';

export const PesquisaSatisfacaoStep = ({question, surveyAnswers, setSurveyAnswers, type}) => {
    return (
        <>
            <Typography
                sx={{
                    marginTop: "13px",
                    fontWeight: "400",
                    fontSize: "24px",
                    lineHeight: "28px",
                    marginBottom: "20px",
                    
                    '@media (max-width: 600px)': {
                        marginBottom: "10px",
                        marginTop: "0px",
                    },
                }}
            >
                {question.pergunta}
            </Typography>
            
            { surveyAnswers[question.id] && type === "BARRA_AVALIACAO" && (
                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Typography variant="h5">Nota: {surveyAnswers[question.id]}</Typography>
                </Box> 
            )}
           
            { type === "BARRA_AVALIACAO" && (
                <>
                    <AvaliationEmoji avaliationNumber={surveyAnswers[question.id]}/>
                    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                        <AvaliationBar question={question} setSurveyAnswers={setSurveyAnswers} surveyAnswers={surveyAnswers}/>
                    </Box>
                </>
            )}

            { type === "INPUT_TEXT" && (
                <PesquisaSatisfacaoTextField question={question} setSurveyAnswers={setSurveyAnswers} surveyAnswers={surveyAnswers}/>
            )}

            { type === "SELECT_OPTION" && (
                <PesquisaSatisfacaoSelectOption question={question} setSurveyAnswers={setSurveyAnswers} surveyAnswers={surveyAnswers}/>
            )}

        </> 
    );
}