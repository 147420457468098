import React, { useEffect, useState } from "react";
import api from "../api/api";
import { Box, Card, CardContent, Grid, FormControl, InputLabel, OutlinedInput, Typography, InputAdornment, IconButton } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import Logo from "../assets/logo.png";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify'
import CustomToast from '../components/CustomToast'

export const LoginProdutor = ({ setExibeIconeHome }) => {
  const [isBackendOnline, setIsBackendOnline] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    usuario: '',
    senha: ''
  });

  useEffect(() => {
    document.querySelector('#manifest-placeholder').setAttribute('href', '/manifest_produtor.json');
    verifyIfBackendIsOnline();
      setExibeIconeHome && setExibeIconeHome(false);
      document.body.style.overflow = "hidden";
      return () => {
          document.body.style.overflow = "auto";
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [])

  const verifyIfBackendIsOnline = async () => {
      try {
          await api.get('/hc');
      } catch (error) {
          setIsBackendOnline(false);
      }
  }

  const handleLogin = async () => {
    setLoading(true);
    if(user.cpf === '' || user.password === '') {
        toast.error('Preencha todos os campos!');
        setLoading(false);
        return;
    }
    
    try {
        const response = await api.post('/auth/produtor', user);
        localStorage.setItem('tokenP', response.data.token);
        window.location.href = '/produtor/home';
    } catch (error) {
        console.log(error);
        CustomToast({message: 'Usuário ou senha inválidos!', type: 'error'});
        setUser({ usuario: '', senha: '' });
    } finally {
        setLoading(false);
    }
  }
  
  return (
    <>
        <Box sx={{
            display: "flex",
            height: "100vh",
            overflow: "hidden",
            flexDirection: "column",
            alignItems: "center",
            bgcolor: "#213648",
        }}>
          <img 
            src={Logo} 
            alt="logo"
            style={{
                width: "390px",
                marginTop: "100px",
            }}
          />
          { !isBackendOnline && (
            <Box sx={{
                display: "flex",
                height: "100vh",
                overflowY: "hidden",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                bgcolor: "#213648",
            }}>
                <Typography variant="h6" sx={{ color: "#D7C46D", marginTop: "20px" }} fontWeight="bold">
                    O sistema está temporariamente indisponível.
                </Typography>
                <Typography variant="h6" sx={{ color: "#D7C46D" }} fontWeight="bold">
                    Tente novamente mais tarde.
                </Typography>
            </Box>
          )}

          { isBackendOnline && (
            <Card variant="elevation" elevation={24} sx={{
                width: "90%",
                padding: "10px",
                '@media (min-width:780px)': { 
                    width: "60%",
                },
                '@media (min-width:1024px)': {
                    width: "40%",
                },
                '@media (min-width:1400px)': {
                    width: "30%",
                },
            }}>
                <CardContent sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <Typography variant="h5" component="h2">
                            Login Produtor
                        </Typography>
                    </Grid>

                    <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                        <InputLabel htmlFor="usuario">USUÁRIO</InputLabel>
                        <OutlinedInput
                            id="usuario"
                            label="USUÁRIO"
                            fullWidth
                            variant="outlined"
                            value={user.usuario}
                            onChange={(e) => setUser({ ...user, usuario: e.target.value })}
                            onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                        />
                    </FormControl>

                    <FormControl noValidate autoComplete="off" sx={{ marginTop: "1rem", width: "100%" }}>
                        <InputLabel htmlFor="password">SENHA</InputLabel>
                        <OutlinedInput
                            id="password"
                            label="SENHA"
                            type={ showPassword ? 'text' : 'password' }
                            fullWidth
                            variant="outlined"
                            value={user.senha}
                            onChange={(e) => setUser({ ...user, senha: e.target.value })}
                            onKeyPress={(e) => e.key === 'Enter' && handleLogin()}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={ event => event.preventDefault() }
                                        edge="end"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }

                        />
                    </FormControl>
                            
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        fullWidth
                        margin="normal"
                        onClick={handleLogin}
                        sx={{ marginTop: "1rem", width: "150px" }}
                        loading={loading}
                    >
                        Entrar
                    </LoadingButton>
                </CardContent>
            </Card>
          )}
        </Box>
        <Box sx={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          color: "#D7C46D",
          textAlign: "center",
          padding: "10px",
          backgroundColor: "#213648",
          zIndex: "1000",
        }}>
          <Typography variant="body2" component="p">
              <b>Versão 1.0.57</b>
          </Typography>
        </Box>
    </>
  );
};
