import { Container, Box, Typography, Divider, Grid, InputLabel, Select, MenuItem, TextField, Button, Fade, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useEffect, useState } from "react";
import { mask, maskRG } from "../themes/masks";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NavbarProdutor from "../components/NavBarProdutor";
import apiProdutor from "../api/apiProdutor";
import moment from 'moment';
import ApoliceItemProdutor from '../components/ApoliceItemProdutor'
import CustomToast from '../components/CustomToast'
import Modal from '../components/Modal'
import TableCustom from '../components/Table'

const SeguradoInfo = ({ dadosSegurado, open, setOpen, show }) => {
    return (
      show && 
      <Grid container spacing={0} sx={{ backgroundColor: '#FFF', borderRadius: '5px', marginTop: '20px', padding: '20px' }}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="body" sx={{ color: '#213648' }}>
            <b>Nome:</b> {dadosSegurado?.nome}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography variant="body" sx={{ color: '#213648' }}>
            <b>CPF/CNPJ:</b> {mask(dadosSegurado?.cpf)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box sx={{ borderBottom: '1px solid #D7C46D', width: '100%', height: '1px' }}></Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => setOpen(!open)}>
              <Typography variant="body" sx={{ color: '#213648', fontWeight: 'bold', textAlign: 'center' }}
                onClick={() => setOpen(!open)}
              >
                {open ? 'FECHAR' : 'MAIS DETALHES'}
              </Typography>
              <ExpandLessIcon sx={{ ml: 1 }} fontSize="large" style={{ transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 0.3s ease-in-out' }} />
            </Box>
            {
              open &&
              <Fade in={open}>
                <Box 
                  sx={{ 
                    backgroundColor: '#fff', 
                    display: 'flex', 
                    justifyContent: 'center', 
                    flexDirection: 'column',
                    marginTop: '10px',
                  }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                          Endereço: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.endereco} - {dadosSegurado?.bairro} - {dadosSegurado?.cep} - {dadosSegurado?.cidadeUF} </span>
                      </Typography>
                    </Grid>
                    { dadosSegurado?.complemento &&
                      <Grid item xs={12} sm={12}>
                        <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                          Complemento: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.complemento}</span>
                        </Typography>
                      </Grid>
                    }
                  </Grid>
                    
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        CPF/CNPJ: <span style={{ fontWeight: 'normal' }}>{mask(dadosSegurado?.cpf)}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        RG: <span style={{ fontWeight: 'normal' }}>{maskRG(dadosSegurado?.rg)}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        Sexo: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.sexo}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        Dt. nasc.: 
                          { dadosSegurado?.nascimento &&
                            <span style={{ fontWeight: 'normal' }}>{moment(dadosSegurado?.nascimento).format('DD/MM/YYYY')}</span>
                          }
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        Contato: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.contato}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        E-mail: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.email}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        Telefone: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.telefone}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3} xl={3}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: "5px", color: '#213648' }}>
                        Celular: <span style={{ fontWeight: 'normal' }}>{dadosSegurado?.celular}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                </Box>
              </Fade>
            }
        </Grid>
      </Grid>
    )
}

export const BuscarSegurados = () => {
    const [objPesquisa, setObjPesquisa] = useState({
      buscar_por: 'cpf_cnpj',
      campo_busca: '',
    })
    const [objPesquisaApolice, setObjPesquisaApolice] = useState({
      ramo: 'todos',
      ano   : 'todos',
      mes   : 0,
      apolice: '',
      situacao: 'ativo'
    })
    const [isMobile, setIsMobile] = useState((window.innerWidth <= 900) ? true : false);
    const [open, setOpen] = useState(false);
    const [seguradoPesquisado, setSeguradoPesquisado] = useState({});
    const [apolices, setApolices] = useState([]);
    const [ramos, setRamos] = useState([]);
    const [modalPesquisaSegurado, setModalPesquisaSegurado] = useState(false);
    const columns = [
      { field: 'nome', headerName: 'NOME', width: 600 },
      { field: 'cpf', headerName: 'CPF/CNPJ', width: 200, renderCell: (params) => ( mask(params.value) ) },
      { field: '', headerName: 'AÇÕES', width: 150, renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ marginLeft: "10px" }}
          onClick={async () => {
            setSeguradoPesquisado({});
            setApolices([]);

            const response = await apiProdutor.get(`/produtor/findSegurado?buscar_por=cpf_cnpj&campo_busca=${params.row.cpf}`);
            setSeguradoPesquisado(response.data[0]);
  
            if (response.data.idSegurado) findApolices(response.data.idSegurado)
            setModalPesquisaSegurado(false);
          }}
        >
          Selecionar
        </Button>
      )},
    ]
    const [segurados, setSegurados] = useState([])

    useEffect(() => {
      window.addEventListener("resize", () => {
        if (window.innerWidth <= 900) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      });
    }, [isMobile]);

    const handlePesquisar = async () => {
      try {
        setSeguradoPesquisado({});
        setApolices([]);

        const responseSegurado = await apiProdutor.get(`/produtor/findSegurado?buscar_por=${objPesquisa.buscar_por}&campo_busca=${objPesquisa.campo_busca}`);
        if(responseSegurado.data.length === 0) {
          CustomToast({message: "Nenhum segurado encontrado", type: "info"});
          setSeguradoPesquisado({});
          setApolices([]);
          return
        }

        if (responseSegurado.data.length === 1) {
          setSeguradoPesquisado(responseSegurado.data[0]);

          if (responseSegurado.data[0].idSegurado) findApolices(responseSegurado.data[0].idSegurado)
          setModalPesquisaSegurado(false);
          return
        }

        setSegurados(responseSegurado.data);
        setModalPesquisaSegurado(true);
      } catch (error) {
        CustomToast({message: "Ocorreu um erro ao pesquisar o segurado", type: "error"});
        console.log(error);
        setSeguradoPesquisado({});
        setApolices([]);
      }
    }

    const findApolices = async (idSegurado) => {
      try {
        setApolices([]);
        const response = await apiProdutor.get(`/produtor/apolices?ramo=${objPesquisaApolice.ramo}&ano=${objPesquisaApolice.ano}&mes=${objPesquisaApolice.mes}&apolice=${objPesquisaApolice.apolice}&situacao=${objPesquisaApolice.situacao}&idSegurado=${idSegurado}`);
        setApolices(response.data);
        const ramos = response.data.map((apolice) => apolice.ramo);
        const ramosUnicos = [...new Set(ramos)];
        setRamos(ramosUnicos);
      } catch (error) {
        CustomToast({message: "Ocorreu um erro ao pesquisar as apólices", type: "error"});
        console.log(error);
      }
    }

    return (
        <>
          <NavbarProdutor />
          <Container sx={{ mb: 4 }}>
            <Box sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "30px",
            }}>
              <Typography variant="h4">
                Buscar segurados
              </Typography>
            </Box>
            <Divider sx={ { marginBottom: "15px" } } />

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
                <InputLabel id="status">Buscar por</InputLabel>
                <Select
                  value={objPesquisa.buscar_por}
                  onChange={(e) => setObjPesquisa({ ...objPesquisa, buscar_por: e.target.value })}
                  fullWidth
                  variant="outlined"
                >
                  <MenuItem value={'cpf_cnpj'}>CPF/CNPJ</MenuItem>
                  <MenuItem value={'nome'}>Nome</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputLabel id="apolice">{objPesquisa.buscar_por === 'cpf_cnpj' ? 'CPF/CNPJ' : 'Nome'}</InputLabel>
                <TextField
                    value={objPesquisa.campo_busca}
                    onChange={(e) => setObjPesquisa({ ...objPesquisa, campo_busca: objPesquisa.buscar_por === 'cpf_cnpj' ? mask(e.target.value) : e.target.value.toUpperCase() })}
                    fullWidth
                    variant="outlined"
                    sx={{ backgroundColor: "white" }}
                    onKeyPress={(e) => e.key === 'Enter' && handlePesquisar()}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                { !isMobile && <InputLabel>&nbsp;</InputLabel> }
                <Button sx={{ height: "55px" }} variant="contained" color="primary" onClick={handlePesquisar}>
                  Pesquisar segurado
                </Button>
              </Grid>
            </Grid>

            <SeguradoInfo dadosSegurado={seguradoPesquisado} open={open} setOpen={setOpen} show={seguradoPesquisado.idSegurado ? true : false} />

            { seguradoPesquisado.idSegurado &&
              <Grid container spacing={1} key={1} sx={{ marginTop: "20px" }}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <InputLabel id="status">Ramo</InputLabel>
                  <Select
                    value={objPesquisaApolice.ramo}
                    onChange={(e) => setObjPesquisaApolice({ ...objPesquisaApolice, ramo: e.target.value })}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value={'todos'}>Todos</MenuItem>
                    {ramos.map((ramo, index) => (
                        <MenuItem value={ramo} key={index}>{ramo}</MenuItem>
                    ))}
                      </Select>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                  <InputLabel id="status">Mês vencimento</InputLabel>
                  <Select
                    value={objPesquisaApolice.mes}
                    onChange={(e) => setObjPesquisaApolice({ ...objPesquisaApolice, mes: e.target.value })}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value={"0"}>Todos</MenuItem>
                    <MenuItem value={"01"}>Janeiro</MenuItem>
                    <MenuItem value={"02"}>Fevereiro</MenuItem>
                    <MenuItem value={"03"}>Março</MenuItem>
                    <MenuItem value={"04"}>Abril</MenuItem>
                    <MenuItem value={"05"}>Maio</MenuItem>
                    <MenuItem value={"06"}>Junho</MenuItem>
                    <MenuItem value={"07"}>Julho</MenuItem>
                    <MenuItem value={"08"}>Agosto</MenuItem>
                    <MenuItem value={"09"}>Setembro</MenuItem>
                    <MenuItem value={"10"}>Outubro</MenuItem>
                    <MenuItem value={"11"}>Novembro</MenuItem>
                    <MenuItem value={"12"}>Dezembro</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                  <InputLabel id="status">Ano vencimento</InputLabel>
                  <Select
                    value={objPesquisaApolice.ano}
                    onChange={(e) => setObjPesquisaApolice({ ...objPesquisaApolice, ano: e.target.value })}
                    fullWidth
                    variant="outlined"
                  >
                    <MenuItem value={'todos'}>Todos</MenuItem>
                    { Array.from(Array(10).keys()).map((ano, index) => (
                      <MenuItem value={ano + new Date().getFullYear()-5} key={index}>{ano + new Date().getFullYear()-5}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <InputLabel id="apolice">Nº Apólice</InputLabel>
                    <TextField
                      value={objPesquisaApolice.apolice}
                      onChange={(e) => setObjPesquisaApolice({ ...objPesquisaApolice, apolice: e.target.value })}
                      fullWidth
                      variant="outlined"
                      sx={{ backgroundColor: "white" }}
                      onKeyPress={(e) => e.key === 'Enter' && findApolices(seguradoPesquisado.idSegurado)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6}>
                  <InputLabel id="radio_ativo">Situação</InputLabel>
                  <RadioGroup
                    row
                    aria-label="radio_ativo"
                    name="radio_ativo"
                    value={objPesquisaApolice.situacao}
                    onChange={(e) => setObjPesquisaApolice({ ...objPesquisaApolice, situacao: e.target.value })}
                  >
                    <FormControlLabel value="ativo" control={<Radio />} label="Ativo" />
                    <FormControlLabel value="inativo" control={<Radio />} label="Inativo" />
                    <FormControlLabel value="todos" control={<Radio />} label="Todos" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={6} xl={6} justifyContent={"end"} display={"flex"}>
                  <Button 
                    sx={{ height: "60px", width: isMobile ? "100%" : "auto" }}
                    variant="contained" 
                    color="primary" 
                    onClick={() => findApolices(seguradoPesquisado.idSegurado)}
                  >
                    Pesquisar apólices
                  </Button>
                </Grid>
              </Grid>
            }

            {Array.isArray(apolices) && apolices.map((apolice, index) => {
              return <ApoliceItemProdutor key={index} apolice={apolice} />})
            }
          </Container>

          <Modal
            title={"Selecione o segurado para buscar as informações"}
            isOpen={modalPesquisaSegurado}
            setIsOpen={setModalPesquisaSegurado}
            maxWidth={"lg"}
            CancelText={"Fechar"}
          >
            <TableCustom columns={columns} rows={segurados} pageSize={10} />        
          </Modal>
        </>
    )
}