import { useEffect, useState } from "react";
import NavBar from "../components/NavBar"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SinitroItem from "../components/SinistroItem";
import api from "../api/api"
import CustomToast from '../components/CustomToast';
import TextField from '@mui/material/TextField';
import moment from "moment";

const Sinistro = () => {
    const [objPesquisa, setObjPesquisa] = useState({
        situacao: 'aberto',
        situacao_apolice: 'ativo',
        ramo: 'todos',
        apolice: '',
    })
    const [loading, setLoading] = useState(true);
    const [sinistros, setSinistros] = useState([]);
    const [ramos, setRamos] = useState([]);

    const pesquisar = async () => {
        try{
            setSinistros([]);
            setLoading(true);
            const response = await api.get('/sinistros?situacao=' + objPesquisa.situacao + '&situacao_apolice=' + objPesquisa.situacao_apolice + '&ramo=' + objPesquisa.ramo + '&apolice=' + objPesquisa.apolice);
            setSinistros(response.data.sinistros);
            const ramos = response.data.ramos.map((ramo) => ramo.ramo);
            const ramosUnicos = [...new Set(ramos)];
            setRamos(ramosUnicos);
        } catch (error) {
            CustomToast({message: "Ocorreu um erro ao pesquisar os sinistros", type: "error"});
            setSinistros([]);
        } finally {
            setLoading(false);
        }
     }   

    useEffect(() => {
        pesquisar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const baixarCSV = async (dados) => {
        try {
            let csvCabecalho = 'APÓLICE;AVARIA BENS;CEP SINISTRO;DATA COMUNICAÇÃO;DATA FINALIZAÇÃO;LOCAL SINISTRO;RAMO;SEGURADORA;STATUS;VALOR INDENIZAÇÃO;\n';
            let csv = csvCabecalho;
            let dadosTratados = removePontoVirgula(dados);

            dadosTratados.forEach((sinistro) => {
                csv += `'${sinistro.apolice};`;
                csv += sinistro.avaria_bens ? sinistro.avaria_bens + ';' : ' ;';
                csv += sinistro.cepsinistro ? sinistro.cepsinistro + ';' : ' ;';
                csv += sinistro.datacomunicacao ? moment(sinistro.datacomunicacao).format('DD/MM/YYYY') + ';' : ' ;';
                csv += sinistro.datafinalizacao ? moment(sinistro.datafinalizacao).format('DD/MM/YYYY') + ';' : ' ;';
                csv += sinistro.localsinistro ? sinistro.localsinistro + ';' : ' ;';
                csv += sinistro.ramo ? sinistro.ramo + ';' : ' ;';
                csv += sinistro.seguradora ? sinistro.seguradora + ';' : ' ;';
                csv += sinistro.status_sinistro ? sinistro.status_sinistro + ';' : ' ;';
                csv += sinistro.valorindenizacao ? sinistro.valorindenizacao + ';' : ' ;';
                csv += ' \n';
            })
            csv = '\uFEFF' + csv;
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sinistros.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    const imprimirPaginaPDF = async () => {
        try {
            window.print();
        } catch (error) {
            console.log(error);
        }
    }

    const removePontoVirgula = (dados) => {
        dados.forEach((sinistro) => {
            Object.keys(sinistro).forEach((key) => {
                if (sinistro[key] && typeof sinistro[key] === 'string') {
                    sinistro[key] = sinistro[key].replace(/;/g, '');
                    sinistro[key] = sinistro[key].replace(/,/g, '');
                }
            })
        })
        return dados;
    }

    return (
        <>
            <NavBar />
            <Container sx={{ mb: 4 }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "30px",
                }}>
                    <Typography variant="h4">
                        Sinistro
                    </Typography>
                </Box>
                <Divider sx={ { marginBottom: "15px" } } />
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <InputLabel id="status">Situação sinistro</InputLabel>
                        <Select
                            value={objPesquisa.situacao}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, situacao: e.target.value })}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>
                            <MenuItem value={'aberto'}>Abertos</MenuItem>
                            <MenuItem value={'fechado'}>Fechados</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6} md={2} lg={2} xl={2}>
                        <InputLabel id="status">Vigência apólice</InputLabel>
                        <Select
                            value={objPesquisa.situacao_apolice}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, situacao_apolice: e.target.value })}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>
                            <MenuItem value={'ativo'}>Ativo</MenuItem>
                            <MenuItem value={'encerrado'}>Encerrado</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputLabel id="ramo">Ramo</InputLabel>
                        <Select
                            value={objPesquisa.ramo}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, ramo: e.target.value })}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>
                            {ramos.map((ramo, index) => (
                                <MenuItem value={ramo} key={index}>{ramo}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputLabel id="apolice">Apólice</InputLabel>
                        <TextField
                            value={objPesquisa.apolice}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, apolice: e.target.value })}
                            fullWidth
                            variant="outlined"
                            sx={{ backgroundColor: "white" }}
                        />
                    </Grid>
                </Grid>
                {/* <Button sx={{ marginTop: "10px" }} variant="contained" color="primary" onClick={() => pesquisar()}>Pesquisar</Button> */}
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    marginTop: "15px",
                }}>
                    <Button sx={{ height: "60px" }} variant="contained" color="primary" onClick={() => pesquisar()}>Pesquisar</Button>
                    <Button sx={{ height: "60px", marginLeft: "10px" }} variant="contained" color="primary" onClick={() => baixarCSV(sinistros)}>Gerar relatório</Button>
                    <Button sx={{ height: "60px", marginLeft: "10px" }} variant="contained" color="primary" onClick={() => imprimirPaginaPDF()}>Imprimir página</Button>
                </Box>
                {loading && (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "50px", alignItems: "center", height: '100px' }}>
                        <CircularProgress color="primary"/>
                        <Typography sx={{ marginLeft: "10px", marginTop: "10px" }} fontWeight="500" variant="h6" color="primary">Carregando...</Typography>
                    </Box>
                )}

                {!loading && (
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        {sinistros.length || 0} sinistros encontrados
                    </Typography>
                )}

                {sinistros?.map((sinistro, index) => { return (<SinitroItem key={index} sinistro={sinistro} />) })}
            </Container>
        </>
    );
}
export default Sinistro;
