/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import NavBar from "../components/NavBar"
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ApoliceItem from "../components/ApoliceItem";
import { useState } from "react";
import { InputLabel, MenuItem, Grid, Select, Button } from "@mui/material";
import api from "../api/api";
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import CustomToast from '../components/CustomToast';

const Apolices = () => {
    const [objPesquisa, setObjPesquisa] = useState({
        ramo: 'todos',
        ano   : 'todos',
        mes   : 0,
        apolice: '',
        situacao: 'ativo'
    })
    const [ramos, setRamos] = useState([]);
    const [apolices, setApolices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        pesquisar();
    }, [])

    const pesquisar = async () => {
        try {
            setLoading(true);
            setApolices([]);
            const response = await api.get(`/apolices?ramo=${objPesquisa.ramo}&ano=${objPesquisa.ano}&mes=${objPesquisa.mes}&apolice=${objPesquisa.apolice}&situacao=${objPesquisa.situacao}`);
            setApolices(response.data);
            const ramos = response.data.map((apolice) => apolice.ramo);
            const ramosUnicos = [...new Set(ramos)];
            setLoading(false);
            setRamos(ramosUnicos);
        } catch (error) {
            setLoading(false);
            CustomToast({message: "Ocorreu um erro ao pesquisar as apólices", type: "error"});
            console.log(error);
        }
    }

    const baixarCSV = async (dados) => {
        try {
            let csvCabecalho = 'APÓLICE;INICIO VIGÊNCIA;FIM VIGÊNCIA;RAMO;SEGURADORA;PARCELAS; \n';
            let csv = csvCabecalho;
            let dadosTratados = removePontoVirgula(dados);

            dadosTratados.forEach((a) => {
                csv += `'${a.apolice};`;
                csv += a.iniciovigencia ? moment(a.iniciovigencia).format('DD/MM/YYYY') + ';' : ' ;';
                csv += a.fimvigencia ? moment(a.fimvigencia).format('DD/MM/YYYY') + ';' : ' ;';
                csv += a.ramo ? a.ramo + ';' : ' ;';
                csv += a.seguradora ? a.seguradora + ';' : ' ;';
                csv += a.total_parcelas ? a.total_parcelas + ';' : ' ;';
                csv += ' \n';
            })
            csv = '\uFEFF' + csv;
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'apolices.csv');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    }

    const removePontoVirgula = (dados) => {
        dados.forEach((sinistro) => {
            Object.keys(sinistro).forEach((key) => {
                if (sinistro[key] && typeof sinistro[key] === 'string') {
                    sinistro[key] = sinistro[key].replace(/;/g, '');
                    sinistro[key] = sinistro[key].replace(/,/g, '');
                }
            })
        })
        return dados;
    }

    const imprimirPaginaPDF = async () => {
        try {
            window.print();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <NavBar />
            <Container sx={{ marginBottom: "50px" }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginTop: "30px",
                }}>
                    <Typography variant="h4">
                        Apólices
                    </Typography>
                </Box>
                <Divider sx={ { marginBottom: "15px" } } />

                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <InputLabel id="status">Ramo</InputLabel>
                        <Select
                            value={objPesquisa.ramo}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, ramo: e.target.value })}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>
                            {ramos.map((ramo, index) => (
                                <MenuItem value={ramo} key={index}>{ramo}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                        <InputLabel id="status">Mês vencimento</InputLabel>
                        <Select
                            value={objPesquisa.mes}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, mes: e.target.value })}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value={"0"}>Todos</MenuItem>
                            <MenuItem value={"01"}>Janeiro</MenuItem>
                            <MenuItem value={"02"}>Fevereiro</MenuItem>
                            <MenuItem value={"03"}>Março</MenuItem>
                            <MenuItem value={"04"}>Abril</MenuItem>
                            <MenuItem value={"05"}>Maio</MenuItem>
                            <MenuItem value={"06"}>Junho</MenuItem>
                            <MenuItem value={"07"}>Julho</MenuItem>
                            <MenuItem value={"08"}>Agosto</MenuItem>
                            <MenuItem value={"09"}>Setembro</MenuItem>
                            <MenuItem value={"10"}>Outubro</MenuItem>
                            <MenuItem value={"11"}>Novembro</MenuItem>
                            <MenuItem value={"12"}>Dezembro</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                        <InputLabel id="status">Ano vencimento</InputLabel>
                        <Select
                            value={objPesquisa.ano}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, ano: e.target.value })}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem value={'todos'}>Todos</MenuItem>
                            { Array.from(Array(10).keys()).map((ano, index) => (
                                <MenuItem value={ano + new Date().getFullYear()-5} key={index}>{ano + new Date().getFullYear()-5}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputLabel id="apolice">Nº Apólice</InputLabel>
                        <TextField
                            value={objPesquisa.apolice}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, apolice: e.target.value })}
                            fullWidth
                            variant="outlined"
                            sx={{ backgroundColor: "white" }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: "10px" }}>
                        <InputLabel id="radio_ativo">Situação</InputLabel>
                        <RadioGroup
                            row
                            aria-label="radio_ativo"
                            name="radio_ativo"
                            value={objPesquisa.situacao}
                            onChange={(e) => setObjPesquisa({ ...objPesquisa, situacao: e.target.value })}
                        >
                            <FormControlLabel value="ativo" control={<Radio />} label="Ativo" />
                            <FormControlLabel value="inativo" control={<Radio />} label="Inativo" />
                            <FormControlLabel value="todos" control={<Radio />} label="Todos" />
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    marginTop: "15px",
                }}>
                    <Button sx={{ height: "60px" }} variant="contained" color="primary" onClick={() => pesquisar()}>Pesquisar</Button>
                    <Button sx={{ height: "60px", marginLeft: "10px" }} variant="contained" color="primary" onClick={() => baixarCSV(apolices)}>Gerar relatório</Button>
                    <Button sx={{ height: "60px", marginLeft: "10px" }} variant="contained" color="primary" onClick={() => imprimirPaginaPDF()}>Imprimir página</Button>
                </Box>
                
                {loading && (
                    <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "50px", alignItems: "center", height: '100px' }}>
                        <CircularProgress color="primary"/>
                        <Typography sx={{ marginLeft: "10px", marginTop: "10px" }} fontWeight="500" variant="h6" color="primary">Carregando...</Typography>
                    </Box>
                )}

                {!loading && (
                    <Typography variant="h6" sx={{ marginTop: "10px" }}>
                        {apolices.length} apólices encontradas
                    </Typography>
                )}

                {Array.isArray(apolices) && apolices.map((apolice, index) => {
                        return (
                            <ApoliceItem key={index} apolice={apolice} />
                        )
                    })
                }
            </Container>
        </>
    );
}
export default Apolices;
