import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  palette: {
    primary: {
      main: "#213648",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#213648",
    },
    danger: {
      main: "#f44336",
      contrastText: "#fff",
    },
    gold: {
      main: "#FFD700",
      contrastText: "#213648",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "#F8F8FA"
        }
      }
    }
  }
});




export default Theme;