export const mask = (cpf_cnpj) => {
    if (!cpf_cnpj) return cpf_cnpj
    cpf_cnpj = cpf_cnpj.replace(/\D/g, "")
  
    if (cpf_cnpj.length <= 11) {
      cpf_cnpj = cpf_cnpj.replace(/(\d{3})(\d)/, "$1.$2")
      cpf_cnpj = cpf_cnpj.replace(/(\d{3})(\d)/, "$1.$2")
      cpf_cnpj = cpf_cnpj.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      cpf_cnpj = cpf_cnpj.replace(/^(\d{2})(\d)/, "$1.$2")
      cpf_cnpj = cpf_cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      cpf_cnpj = cpf_cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
      cpf_cnpj = cpf_cnpj.replace(/(\d{4})(\d)/, "$1-$2")
    }
  
    return cpf_cnpj
}

export const maskPhone = (phone) => {
  phone = phone.replace(/\D/g, "").substring(0, 10);
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2")
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2")
  return phone
}

export const maskCell = (cell) => {
  cell = cell.replace(/\D/g, "").substring(0, 11);
  cell = cell.replace(/^(\d{2})(\d)/g, "($1) $2")
  cell = cell.replace(/(\d)(\d{4})$/, "$1-$2")
  return cell
}

export const maskCep = (cep) => {
  cep = cep.replace(/\D/g, "").substring(0, 8);
  cep = cep.replace(/^(\d{5})(\d)/g, "$1-$2")
  return cep
}

export const maskRG = (rg) => {
  // Remover qualquer caractere que não seja um dígito
  if(!rg) return rg
  rg = rg.replace(/\D/g, '');

  // Verificar o comprimento do RG
  if (rg.length < 2) {
    return rg; // Não é possível formatar corretamente um RG com menos de 2 dígitos
  } else if (rg.length <= 6) {
    // Formatar RG com até 6 dígitos
    return rg.replace(/(\d{1,2})(\d{1,3})/, '$1.$2');
  } else if (rg.length < 9) {
    // Formatar RG com até 9 dígitos
    return rg.replace(/(\d{1,2})(\d{3})(\d{1,3})/, '$1.$2.$3');
  } else {
    // Formatar RG com mais de 9 dígitos
    return rg.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1,3})/, '$1.$2.$3-$4');
  }
}