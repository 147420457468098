import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Toolbar,
  Typography,
  makeStyles,
  Box
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import LogoPreto from "../assets/logo_preto.png";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "../components/Modal";
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';

const useStyles = makeStyles((theme) => ({
  link: { textDecoration: "none", color: "black", fontSize: "25px", display: "flex", alignItems: "center" },
  icon: { color: "black", marginRight: theme.spacing(1), fontSize: "2.5rem" },
  logo: { flexGrow: "1", cursor: "pointer" },
  desconectButton: {
    "&:hover": {
      transform: "scale(1.2)",
      transition: "transform 0.2s ease-in-out",
    },
  }
}));

function DrawerComponentProdutor() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Drawer
        anchor="top"
        sx={{ width: 250, color: "#fff" }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onClick={() => setOpenDrawer(false)}
      >
        <Toolbar>
          <Typography variant="h4" className={classes.logo} onClick={() => navigate("/produtor/home")}>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", marginTop: "10px"}}>
              <img src={LogoPreto} alt="logo" style={{ width: "230px", height: "100%" }} />
            </Box>
          </Typography>
          <CloseIcon onClick={() => setOpenDrawer(false)} />
        </Toolbar>

          <List height="100vh">
            <Divider />
            <ListItem onClick={() => setOpenDrawer(false)}>
              <ListItemText>
                <Link to="/produtor/home" className={classes.link}>
                <HomeIcon className={classes.icon} /> Home
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
                <Link to="/produtor/buscar_segurados" className={classes.link}>
                  <PersonSearchIcon className={classes.icon} /> Buscar Segurados
                </Link>
              </ListItemText>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText>
              <Button variant="contained" color="danger" className={classes.desconectButton} onClick={() => setOpenModal(true)}>
              <LogoutIcon /> Desconectar 
            </Button>
              </ListItemText>
            </ListItem>
          </List>
      </Drawer>
      <IconButton
        className={classes.icon}
        style={{ color: "#fff" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
      <Modal 
        isOpen={openModal} 
        setIsOpen={setOpenModal} 
        confirmText="Desconectar"
        CancelText="Cancelar"
        title="Deseja realizar logout?"
        onConfirm={() => {
            localStorage.removeItem("tokenP");
            navigate("/produtor/login");
        }}
        maxWidth={"sm"}
      />
    </>
  );
}

export default DrawerComponentProdutor;
