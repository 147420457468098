import { Box } from '@mui/material'

const AvaliationEmoji = ({ avaliationNumber }) => {
    const emoji_coracao = require('../assets/emoji/emoji_coracao.png')
    const emoji_sorrindo = require('../assets/emoji/emoji_sorrindo.png')
    const emoji_triste = require('../assets/emoji/emoji_triste.png')
    const emoji_estrela = require('../assets/emoji/emoji_estrela.png')
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginBottom={2}>
            { avaliationNumber > 0 && avaliationNumber <= 3 && <img src={emoji_triste} alt="emoji triste" style={{ width: '122px', height: '114px' }} /> }
            { avaliationNumber <= 6 && avaliationNumber > 3 && <img src={emoji_sorrindo} alt="emoji sorrindo" style={{ width: '122px', height: '114px' }} /> }
            { avaliationNumber <= 9 && avaliationNumber > 6 && <img src={emoji_coracao} alt="emoji coração" style={{ width: '122px', height: '114px' }} /> }
            { avaliationNumber === 10 && <img  alt="emoji muito feliz" src={emoji_estrela} style={{ width: '122px', height: '114px' }} /> }
        </Box>
    )
}

export default AvaliationEmoji