import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api/api"
import CustomToast from "../components/CustomToast";

const PrivateRouteProdutor = ({ children }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('tokenP');

    useEffect(() => {
        if(!token) navigate('/produtor/login');

        (async () => {
            try{
                const response = await api.get('/validTokenProdutor', { headers: { 'Authorization': `${token}` } })
                if(!response.data) navigate('/produtor/login');
                return children;
            } catch (error) {
                CustomToast({ message: 'Sua sessão expirou, faça login novamente', type: 'error' });
                navigate('/produtor/login');
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate])

    return children;
};

export default PrivateRouteProdutor;