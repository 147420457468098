import Navbar from "../components/NavBar"
import { useLocation, useNavigate } from "react-router-dom"
import Typography  from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import moment from "moment/moment"
import Button from '@mui/material/Button';

export const SinistroSelecionado = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const sinistro = state.sinistro;

    if(!sinistro) {
        return (
            <>
                <Navbar />
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 20px" }}>
                    <Typography component="h6" variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                        Ocorreu um erro ao carregar o sinistro, tente novamente mais tarde.
                    </Typography>
                </Box>
            </>
        )
    }

    return (
        <>
            <Navbar />
            <Grid container spacing={2} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "20px 10px" }}>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
                    <div style={{boxShadow: "0px 0px 20px 1px rgba(0,0,0,0.15)", borderRadius: "5px"}}>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#D7C46D', borderRadius: '5px 5px 0px 0px', padding: '10px 20px', minHeight: '50px', flexWrap: 'wrap' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#213648', marginRight: '20px' }}>
                                SINISTRO: {sinistro.id_sinistro}
                            </Typography>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#213648' }}>
                                APÓLICE: {sinistro.apolice}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: '#213648', padding: '10px 20px', minHeight: '30px' }}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#FFF', textTransform: 'uppercase', marginLeft: '5px' }}>
                                {sinistro.ramo}
                            </Typography>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#FFF', textTransform: 'uppercase', marginLeft: '5px' }}>
                                {sinistro.status_sinistro || 'Aberto'}
                            </Typography>
                        </Box>
                        <div style={{ padding: "10px 20px" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexWrap: 'wrap' }}>
                                <Typography component="h6" variant="h6" sx={{ fontWeight: "bold", marginRight: "10px" }}>
                                    Data da ocorrência: 
                                </Typography>
                                <Typography component="h6" variant="h6" sx={{ fontWeight: "400" }}>
                                    { sinistro.dataocorrencia ? moment(sinistro.dataocorrencia).format("DD/MM/YYYY") : "Não informado" }
                                </Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexWrap: 'wrap' }}>
                                <Typography component="h6" variant="h6" sx={{ fontWeight: "bold", marginRight: "10px" }}>
                                    Data da comunicação: 
                                </Typography>
                                <Typography component="h6" variant="h6" sx={{ fontWeight: "400" }}>
                                    { sinistro.datacomunicacao ? moment(sinistro.datacomunicacao).format("DD/MM/YYYY") : "Não informado" }
                                </Typography>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexWrap: 'wrap' }}>
                                <Typography component="h6" variant="h6" sx={{ fontWeight: "bold", marginRight: "10px" }}>
                                    Valor previsto do prejuízo: 
                                </Typography>
                                <Typography component="h6" variant="h6" sx={{ fontWeight: "400" }}>
                                    {sinistro.valorindenizacao ? "R$ " + sinistro.valorindenizacao.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : "Não informado"}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Button sx={{ margin: "10px 10px" }} variant="contained" color="primary" onClick={() => navigate(-1)}>
                Voltar
            </Button>
        </>
    )
}

export default SinistroSelecionado;
