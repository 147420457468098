import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Registration from "../pages/Registration"
import Sinistro from "../pages/Sinistro"
import Apolices from "../pages/Apolices"
import FrequentlyAnswers from "../pages/FrequentlyAnswers"
import ApoliceSelecionada from "../pages/ApoliceSelecionada"
import PrivateRoute from "./PrivateRoute";
import AprAltCadastro from "../pages/AprAltCadastro";
import SinistroSelecionado from "../pages/SinistroSelecionado";
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { useState } from "react";
import PesquisaSatisfacao from "../pages/PesquisaSatisfacao"
import { PaginaNaoEncontrada } from "../pages/PaginaNaoEncontrada"
import { BuscarSegurados } from "../pages/BuscarSegurados"
import { LoginProdutor } from "../pages/LoginProdutor"
import HomeProdutor from "../pages/HomeProdutor"
import PrivateRouteProdutor from "./PrivateRouteProdutor"

const RoutesApp = () => {
    const [exibeIconeHome, setExibeIconeHome] = useState(true);

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login setExibeIconeHome={setExibeIconeHome} />} />
                <Route exact path="/" element={<Login setExibeIconeHome={setExibeIconeHome} />} />
                <Route path="/pesquisa_satisfacao" element={<PesquisaSatisfacao setExibeIconeHome={setExibeIconeHome}/>} />
                <Route path="/home" element={<PrivateRoute><Home setExibeIconeHome={setExibeIconeHome}/></PrivateRoute>} />
                <Route path="/registration" element={<PrivateRoute><Registration /></PrivateRoute>} />
                <Route path="/sinistro" element={<PrivateRoute><Sinistro /></PrivateRoute>} />
                <Route path="/apolice" element={<PrivateRoute><Apolices /></PrivateRoute>} />
                <Route path="/atendimento" element={<PrivateRoute><FrequentlyAnswers /></PrivateRoute>} />
                <Route path="/apoliceSelecionada" element={<PrivateRoute><ApoliceSelecionada /></PrivateRoute>} />
                <Route path="/apr_alt_cadastro" element={<PrivateRoute><AprAltCadastro /></PrivateRoute>} />
                <Route path="/sinistroSelecionado" element={<PrivateRoute><SinistroSelecionado /></PrivateRoute>} />

                <Route exact path="/produtor/login" element={<LoginProdutor setExibeIconeHome={setExibeIconeHome} />} />
                <Route path="/produtor/buscar_segurados" element={<PrivateRouteProdutor><BuscarSegurados /></PrivateRouteProdutor>} />
                <Route path="/produtor/home" element={<PrivateRouteProdutor><HomeProdutor setExibeIconeHome={setExibeIconeHome}/></PrivateRouteProdutor>} />
                <Route path="*" element={<PrivateRoute><PaginaNaoEncontrada setExibeIconeHome={setExibeIconeHome} /></PrivateRoute>} />
            </Routes>

            { exibeIconeHome &&
                <Link 
                  to={window.location.pathname.includes('produtor') ? '/produtor/home' : '/home'} 
                  style={{ textDecoration: "none", color: "#fff", backgroundColor: "red" }}
                >
                    <Box 
                        sx={{ 
                            position: "fixed",
                            bottom: "30px", 
                            right: "30px", 
                            zIndex: "1",
                            backgroundColor: "#213648",
                            borderRadius: "50%",
                            padding: "15px",
                            cursor: "pointer",
                            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.55)",
                            '@media (max-width: 600px)': {
                                bottom: "10px",
                                right: "10px",
                                padding: "10px"
                            }
                        }}
                    >   
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <HomeIcon style={{ color: "#fff", fontSize: "30px" }} />
                        </div>
                    </Box>
                </Link>
            }
        </BrowserRouter>
    )
}

export default RoutesApp;