import { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TableCustom from "../components/Table"
import api from "../api/api";
import { Button } from "@mui/material"
import CustomToast from '../components/CustomToast';

const AprAltCadastro = () => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        getUsersPendingApproval();
    }, []);

    const getUsersPendingApproval = async () => {
        const response = await api.get("/pending-approval-alter-cadastre");
        setRows(response.data);
    };

    const handleReprove = async (id) => {
        try{
            const response = await api.put(`/reprove-alter-cadastre?id=${id}`);
            if (!response.status === 200) CustomToast({message: "Ocorreu um erro ao recusar o cadastro!", type: "error"})
            getUsersPendingApproval();
            CustomToast({message: "Atualização de cadastro recusado com sucesso!", type: "success"})
        } catch (error) {
            CustomToast({message: "Ocorreu um erro ao recusar o cadastro!", type: "error"});
        }
    };

    const handleApprove = async (id) => {
        try{
            const response = await api.put(`/approve-alter-cadastre?id=${id}`);
            if (!response.status === 200) CustomToast({message: "Ocorreu um erro ao aprovar o cadastro!", type: "error"})
            getUsersPendingApproval();
            CustomToast({message: "Atualização de cadastro aprovado com sucesso!", type: "success"})
        } catch (error) {
            CustomToast({message: "Ocorreu um erro ao aprovar o cadastro!", type: "error"});
        }
    };

    return (
        <>
            <NavBar />
            <Container sx={{ backgroundColor: 'white', padding: '1rem', marginTop: '1rem', paddingX: '2rem' }} maxWidth="xl">
                <Typography variant="h4">
                    Cadastros aguardando aprovação para alteração
                </Typography>
                <Divider sx={{ marginBottom: '2rem' }} />

                <TableCustom
                    columns={[
                        { field: 'acao', headerName: 'AÇÃO', width: 200, renderCell: (params) => (
                            <div style={{ gap: '0.5rem', display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" size="small" color="success" onClick={() => handleApprove(params.row.id)}>Aprovar</Button>
                                <Button variant="contained" size="small" color="error" onClick={() => handleReprove(params.row.id)}>Reprovar</Button>
                            </div>
                        ) },
                        { field: 'idsegurado', headerName: 'ID', minWidth: 70 },
                        { field: 'nome', headerName: 'Nome', minWidth: 350 },
                        { field: 'data_solicitacao', headerName: 'Data Solicitado', minWidth: 170 },
                        { field: 'email', headerName: 'E-MAIL', minWidth: 230 },
                        { field: 'telefone', headerName: 'Telefone', minWidth: 130 },
                        { field: 'celular', headerName: 'Celular', minWidth: 130 },
                        { field: 'cep', headerName: 'CEP', minWidth: 90 },
                        { field: 'endereco', headerName: 'Endereço', minWidth: 400 },
                        { field: 'bairro', headerName: 'Bairro', minWidth: 200 },
                        { field: 'cidade_nome', headerName: 'Cidade', minWidth: 150 },
                        { field: 'complemento', headerName: 'Complemento', minWidth: 400 }
                    ]}
                    rows={rows}
                />
            </Container>
        </>
    );
}

export default AprAltCadastro;