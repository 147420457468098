import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material"

const RamoApolice = ({ ramoApolice, numeroApolice }) => {

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const encurtarTexto = (texto) => {
    if ( texto === 'TRANSPORTE INTERNACIONAL IMPORTAÇÃO') return 'TRANSP. INTERNACIONAL IMPORT.';
    if ( texto === 'RESPONSABILIDADE CIVIL TRANSP. REGIONAL') return 'RESP. CIVIL TRANSP. REGIONAL';
    if ( texto === 'RESP. CIVIL DE ADM. E DIRETORES (D&O)') return 'RESP. CIVIL TRANSP. ROD.';
    if ( texto === 'GARANTIAS E OBRIGAÇÕES CONTRATUAIS') return 'GARANTIAS E OBRIG. CONTRAT.';
    if ( texto === 'RESPONSABILIDADE CIVIL RISCOS AMBIENTAIS') return 'RESP. CIVIL RISCOS AMBIENT.';
    if ( texto === 'CAPITALIZAÇÃO - GARANTIA FINANCEIRA') return 'CAPITALIZAÇÃO GARANT. FIN.';
    if ( texto === 'RESPONSABILIDADE CIVIL PROFISSIONAL') return 'RESP. CIVIL PROFISSIONAL';

    return texto;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  return (
    <Box 
        sx={{ 
            display: "flex",
            justifyContent: "center",
            borderTopRightRadius: "5px", 
            flexDirection: "column",
            gap: "2px",
        }} 
    >
    <Box sx={{ display: "flex", flexWrap: 'wrap', justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#213648", borderTopRightRadius: "5px", color: "#fff" }}>
      <Typography sx={{ color: "#fff", fontWeight: 700, padding: "2px 10px" }}>
          APÓLICE {numeroApolice}
      </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", backgroundColor: "#D7C46D", color: "#fff", flexWrap: 'wrap' }}>
      <Typography sx={{ color: "#000000", fontWeight: 700, padding: "2px", fontSize: "14px" }}>
          { windowDimensions.width > 600 ? ramoApolice.nome : encurtarTexto(ramoApolice.nome) }
      </Typography>
    </Box>
    </Box>
  );
}

export default RamoApolice;