import { useEffect, useState } from "react"
import { Card, Typography, Box, LinearProgress, Button, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { Money, CalendarToday, InsertDriveFile, MonetizationOn, Event, Today } from '@mui/icons-material';
import RamoApolice from "./RamoApolice"
import { notFoundImg } from "../assets/notFoundImg";
import InsuranceCompany from "./InsuranceCompany";
import Modal from "./Modal";
import api from "../api/api";
import Table from "./Table";
import LoadingButton from '@mui/lab/LoadingButton';
import TipoApoliceItem from "./TipoApoliceItem"

const ApoliceItem = ({ apolice }) => {
    const [progress, setProgress] = useState(0)
    const [showModalApolice, setShowModalApolice] = useState(false)
    const [archieves, setArchieves] = useState([])
    const [carroPesquisa, setCarroPesquisa] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        const monthsToExpire = moment(apolice.fimvigencia).diff(moment(), 'months')
        const calcProgress = ((((monthsToExpire - 12) / 12) * 100) * -1)

        if(calcProgress > 100) setProgress(100)
        else setProgress(calcProgress)
    }, [apolice.fimvigencia])

    const handleShowModal = async() => {
        setShowModalApolice(true)

        const archieves = await api.get(`/apoliceArchieve?apolice=${apolice.apolice}`)
        setArchieves(archieves.data)
    }

    const handleDownloadApolice = async (row, e) => {
        e.target.innerHTML = "Baixando..."
        e.target.disabled = true
        e.target.style.backgroundColor = "#3f51b5"
        e.target.style.color = "#fff"
        e.target.loading = true      

        try{
            const response = await api.get(`/downloadArchieve?id_cabec=${row.id}`)
            const arrayBufferPdf = response.data[0].arquivo.data
            const bytes = new Uint8Array(arrayBufferPdf)
            const blob = new Blob([bytes], { type: "application/pdf" })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = row.archive_name
            link.click()
        } catch(err) {
            console.log(err)
        } finally {
            setTimeout(() => {
                e.target.innerHTML = "DOWNLOAD"
                e.target.disabled = false
                e.target.style.backgroundColor = "#213648"
                e.target.style.color = "#fff"
                e.target.loading = false  
            }, 2000)
        }
    }

    return (
        <>
            <Grid container spacing={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', mt: 2 }}>
                <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                    <Card sx={{ borderTopRightRadius: "5px", borderTopLeftRadius: "5px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <img 
                                    src={ apolice.ramo_foto ? `data:image/jpeg;base64,${apolice.ramo_foto}` : `data:image/jpeg;base64,${notFoundImg}` }
                                    alt="Ramo" 
                                    style={{ borderTopLeftRadius: '4px', width: '103%', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.5)', objectFit: 'cover', height: '108px' }}
                                />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <RamoApolice ramoApolice={{ nome: apolice.ramo }} numeroApolice={apolice.apolice} />
                                <InsuranceCompany insuranceCompany={{ nome: apolice.seguradora, logo: apolice.logo_seguradora }}/>
                            </Grid>
                        </Grid>

                        {/* Linha com os calendarios */}
                        <Box sx={{ padding: "0px 16px 16px 16px" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <Today sx={{ fontSize: 40, color: "#757575" }} />
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                                        <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 500, color: "black" }}>
                                            Iní­cio
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                            {moment(apolice.iniciovigencia).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                        <Typography variant="h6">
                                            Vigência
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={5} sm={5} md={5} lg={5} xl={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column', width: "100%", mr: 2 }}>
                                        <Typography variant="h6" sx={{ fontSize: 18, fontWeight: 500, color: "black" }}>
                                            Fim
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                            {moment(apolice.fimvigencia).format("DD/MM/YYYY")}
                                        </Typography>
                                    </Box>
                                    <Event sx={{ fontSize: 40, color: "#757575" }} />
                                </Grid>
                            </Grid>
                            {/* Fim linha com os calendarios */}

                            <LinearProgress variant="determinate" value={progress} sx={{ height: 8, borderRadius: 5 }} />

                            <Box sx={{ p: 2  }}>
                            {/* Linha 1 com os valores */}
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <MonetizationOn sx={{ fontSize: 25, color: "#757575" }} />
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                                        <Typography sx={{ fontSize: 13, color: "black" }}>
                                            Valor Parcela
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                        {parseFloat(apolice.valor_parcela_atual).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column', width: "100%", mr: 2 }}>
                                        <Typography sx={{ fontSize: 13, color: "black" }}>
                                            Valor Total
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                            {parseFloat(apolice.premioconfirmado).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                    </Box>
                                    <InsertDriveFile sx={{ fontSize: 25, color: "#757575" }} />
                                </Grid>
                            </Grid>
                            {/* Fim Linha 1 com os valores */}

                            {/* Linha 2 com os valores */}
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <CalendarToday sx={{ fontSize: 25, color: "#757575" }} />
                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", ml: 2 }}>
                                        <Typography sx={{ fontSize: 13, color: "black" }}>
                                            Data Vencimento
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                            {apolice.vencimento_parcela_atual}
                                        </Typography>
                                    </Box>
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flexDirection: 'column', width: "100%", mr: 2 }}>
                                        <Typography sx={{ fontSize: 13, color: "black" }}>
                                            Parcelas
                                        </Typography>
                                        <Typography sx={{ fontSize: 14, fontWeight: 900, color: "#E4CF6F" }}>
                                            {apolice.parcela_atual}/{apolice.total_parcelas}
                                        </Typography>
                                    </Box>
                                    <Money sx={{ fontSize: 25, color: "#757575" }} />
                                </Grid>
                            </Grid>
                            {/* Fim Linha 2 com os valores */}

                            {/* Linha 3 com os valores */}
                            <Grid container spacing={1}>
                                <TipoApoliceItem apolice={apolice} idTipoApolice={apolice.id_ramo} setCarroPesquisa={setCarroPesquisa} carroPesquisa={carroPesquisa} />
                            </Grid>
                            {/* Fim Linha 3 com os valores */}

                            {/* Linha 4 com os valores */}
                            { carroPesquisa &&
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', mt: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%", backgroundColor: "#EAEDED", p: 2, borderRadius: 1 }}>
                                                <>
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%" }}>
                                                        <Typography sx={{ fontSize: 13, color: "#E4CF6F", fontWeight: 'bold' }}>
                                                            Item
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "#213648" }}>
                                                            {carroPesquisa.item}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column', width: "100%" }}>
                                                        <Typography sx={{ fontSize: 13, color: "#E4CF6F", fontWeight: 'bold' }}>
                                                            Veículo
                                                        </Typography>
                                                        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "#213648" }}>
                                                            {carroPesquisa.nome}
                                                        </Typography>
                                                    </Box>
                                                </>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </Box>

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2, mt: 2 }}>
                                <Button 
                                    variant="contained" 
                                    sx={{ width: "150px", 
                                        backgroundColor: "#E4CF6F", 
                                        color: "black", 
                                        fontWeight: 900,
                                        fontSize: 14, 
                                        "&:hover": { backgroundColor: "#E4CF6F", transform: "scale(1.05)" } 
                                    }}
                                    onClick={() => handleShowModal()}
                                >
                                    Apólice
                                </Button>
                                <Button 
                                    color="danger"
                                    variant="contained" 
                                    sx={{ 
                                        width: "150px", 
                                        fontWeight: 900, 
                                        fontSize: 14, 
                                        "&:hover": { backgroundColor: "#FE6C6C", transform: "scale(1.05)" } 
                                    }}
                                    onClick={() => navigate("/atendimento")}
                                >
                                Atendimento
                                </Button>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <Modal 
                isOpen={showModalApolice}
                setIsOpen={() => setShowModalApolice(false)}
                CancelText="Fechar"
                maxWidth={"md"}
                children={
                    <Table
                        columns={[
                            {
                                field: 'archive_name',
                                headerName: 'APÓLICE/ENDOSSO',
                                width: 800,
                                renderCell: (params) => (
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography>
                                            {params.row.archive_name}
                                        </Typography>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick={(e) => handleDownloadApolice(params.row, e)}
                                            sx={{ mt: 1, width: "120px" }}
                                        >
                                            Download
                                        </LoadingButton>
                                    </Box>
                                )
                            }
                        ]}
                        rows={archieves}
                        showToolbar={false}
                        rowHeight={80}
                    />
                }
            /> 
        </>
    )
}

export default ApoliceItem;