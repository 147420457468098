import React from 'react';
import { Box, Typography } from '@mui/material';

export const PesquisaSatisfacaoCompleted = () => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" marginTop="40px">
            <Typography variant="h6">
                Obrigado por responder a pesquisa!
            </Typography>
            <Typography variant="h6">
                Seu feedback é muito importante para nós!
            </Typography>
        </Box>
    );
}