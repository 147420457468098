import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import Theme from './themes/Theme';
import { Slide, ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={Theme}>
    <ToastContainer
      position="top-right"
      autoClose={4000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      theme='dark'
      transition={Slide}
    />
      <CssBaseline enableColorScheme />
      <App />
  </ThemeProvider>
);