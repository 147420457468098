import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Typography,
  useTheme,
  useMediaQuery
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponentProdutor from "./DrawerProdutor";
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import Modal from "./Modal";
import Logo from "../assets/logo.png";
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

const useStyles = makeStyles((theme) => ({
  navlinks: { marginLeft: theme.spacing(10), display: "flex", alignItems: "center" },
  logo: { flexGrow: "1", cursor: "pointer" },
  icon: { color: "white", marginRight: theme.spacing(0.5), fontSize: "1.5rem" },
  appbar: { backgroundColor: "#213648" },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
    "&:hover": {
      transform: "scale(1.2)",
      transition: "transform 0.2s ease-in-out"
    }
  },
  desconectButton: {
    marginLeft: "20px !important",
    "&:hover": {
      transform: "scale(1.2)",
      transition: "transform 0.2s ease-in-out",
    }
}}));

function NavbarProdutor() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <AppBar position="static" className={classes.appbar}>
      <Toolbar>
        <Typography variant="h4" className={classes.logo} onClick={() => navigate("/produtor/home")}>
          <img src={Logo} alt="logo" style={ !isMobile ? { width: "200px", height: "100%" } : { width: "150px", height: "100%" } } />
        </Typography>
        {isMobile ? (
          <DrawerComponentProdutor />
        ) : (
          <div className={classes.navlinks}>
            <Link to="/produtor/home" className={classes.link}>
              <HomeIcon className={classes.icon} /> Home
            </Link>
            <Link to="/produtor/buscar_segurados" className={classes.link}>
              <PersonSearchIcon className={classes.icon} /> Buscar Segurados
            </Link>
            <Button variant="contained" color="danger" className={classes.desconectButton} onClick={() => setOpenModal(true)}>
              <LogoutIcon className={classes.icon} /> Desconectar 
            </Button>
          </div>
        )}
      </Toolbar>
      <Modal 
        isOpen={openModal} 
        setIsOpen={setOpenModal} 
        confirmText="Desconectar"
        CancelText={"Cancelar"}
        title="Deseja realizar logout?"
        maxWidth="sm"
        onConfirm={() => {
            localStorage.removeItem("token");
            navigate("/produtor/login");
        }}
      />
    </AppBar>
  );
}

export default NavbarProdutor;
